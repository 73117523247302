import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { NEVER, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TokenService } from '@app/xServices/token.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private router: Router, private tokenService: TokenService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const idToken = localStorage.getItem("accessToken");

        if (idToken) {
            request = request.clone({ headers: request.headers.set("Authorization", "Bearer " + idToken) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status)) {
                // auto logout if 401 or 403 response returned from api

                return this.tokenService.RefreshToken().pipe(
                    switchMap((res: any) => {
                        if (res) {
                            let newToken = res.responseData;
                            this.tokenService.SetToken(newToken.accessToken);
                            this.tokenService.SetRefreshToken(newToken.refreshToken);
                            request = request.clone({ headers: request.headers.set("Authorization", "Bearer " + newToken.accessToken) });
                            return next.handle(request).pipe(catchError(err => {
                                this.router.navigate(['login']);
                                return NEVER;
                            }));
                        }
                        this.router.navigate(['login']);
                        return NEVER;
                    }),
                )
            }
            if ([412].includes(err.status)) {
                this.router.navigate(['registration']);
                return NEVER;
            }
            if ([404].includes(err.status) ) {
                const error = (err && err.error && err.error.message) || err.statusText;
                console.warn(err);
                console.info(err.description);
                throw new Error("Warning. Not found data.");
                  
            }
            else{
                const error = (err && err.error && err.error.message) || err.statusText;
                console.error(err);
                console.error(err.description);
                return throwError(() => error);
            }
        }));
    }

}