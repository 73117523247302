<div class="container mt-5">
  <div class="row ">
    <div class="col-md-12 top-img">
    </div>
  </div>

  <!-- 1. CONTACT SECTION-->
  <ctg-user-contacts></ctg-user-contacts>

  <!-- 2. MEMBER SHIP SECTION-->
  <ctg-user-membership [ContactId]="userId" > <!-- (sendEmployerPlaceNameEvent)="onSaveDataWorkplace($event)" -->
  </ctg-user-membership>

  <!-- 3. Arbeidsgiver SECTION -->
  <!-- 3.1 Work Place -->
  <div class="row ">
    <div class="col-12 bg-primary-header ps-4 mt-3  text-start">
      <p class="fs-4 fw-normal pt-3">Arbeidsgiver</p>
    </div>
  </div>
  <div class="row ">
    <div class="col-12 pt-2 ">
      <app-user-workplace [ContactId]="userId" >
      </app-user-workplace>
    </div>
  </div>

 <!-- 3.2 Education -->
 <div class="row ">
    <div class="col-12 bg-primary-header ps-4 mt-3  text-start">
      <p class="fs-4 fw-normal pt-3">Utdanning</p>
    </div>
  </div>
 <div class="row ">
  <div class="col-12 pt-2 ">
    <app-user-education [ContactId]="userId" >
    </app-user-education>
  </div>
</div>

  <!-- 4. Mine betalinger -->
  <!-- <aside class="container container-main col-12"></aside>-->
  <div class="row ">
    <div class="col-12 bg-primary-header  mt-5 ps-4 text-start">
      <p class="fs-4 fw-normal pt-3">Mine betalinger</p>
    </div>
  </div>
  <ctg-user-invoices></ctg-user-invoices>
  

  <!-- FOOTER BANNER -->
  <div class="row justify-content-center  mb-5">
    <a class="col-12 bottom-img text-center pt-5 text-decoration-none" [routerLink]="['/membershipbenefits']">
      <h1 class="pt-4">Se alle dine Medlemsfordeler her</h1>
    </a>
  </div>

</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Laster... </p>
</ngx-spinner>