import { Selector } from "@ngxs/store";
import { ContactIdState, ContactIdStateModel } from "./contact-id.state";

export class ContactIdSelectors {    
   
    @Selector([ContactIdState])
    static getContactIdItems(state: ContactIdStateModel) :string{
       
        return state.contactIdData;
    }
}