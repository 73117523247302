
    <div class="row  container-small position-relative pt-5 " >

      <div class="col-12  my-4 mb-5 text-center">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <td>Fakturanr.</td>
                <td>Fakturadato</td>
                <td>Forfall</td>
                <td>Beløp</td>
                <td>Restbeløp</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <div *ngIf="invoiceData && invoiceData.length > 0 ; else noInvoiceData">

              </div>
              <ng-template #noInvoiceData>
                <tr>
                  <td colspan="6" class="text-center p-5">
                    Ingen fakturainformasjon funnet
                  </td>
                </tr>
              </ng-template>
              
              <ng-container *ngFor="let item  of invoiceData; let i = index">
                <tr [class]="(i > 4) ? 'invoice-history collapse' : ''">
                  <td>{{item.invoiceNumber}}</td>
                  <td>{{item.invoiceDate  | date:'dd.MM.yy'}}</td>
                  <td>{{item.invoiceDueDate  | date:'dd.MM.yy'}}</td>
                  <td>{{item.amount}} kr</td>
                  <td>{{item.amountOutstanding}} kr</td>
                  <td>
                    <a (click)="downloadPDF(item.id)" style="cursor: pointer;">
                    <svg fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/><polyline points="7 10 12 15 17 10"/><line x1="12" x2="12" y1="15" y2="3"/></svg>
                  </a>
                </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <button class=" btn text-nowrap " style="margin-left: 10px;text-align:left;width: auto;" (click)="toggleInvoiceHistory()"
        *ngIf="invoiceData.length > 4">{{this.showHistory ?"Se mindre": "Se mer..." }}</button>
    </div>