import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Directive, ElementRef, Inject, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { BankidService } from '@app/xServices/bankid.service';
import { EncryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { VippService } from '@app/xServices/vipp.service';
import { environment } from '@environments/environment';


@Component({
  selector: 'ctg-vipps-login',
  template: '',
  styleUrls: ['./vipps-login.component.scss']
})

export class VippLginComponent implements OnInit {
  private sub: any;
  private mode: string = '';
  private authCode: string = '';

  constructor( @Inject(DOCUMENT) private document: Document,  
    private encryptPipe: EncryptPipe, 
    private service: VippService,
    private r: ActivatedRoute
  ) {
    this.sub = this.r.params.subscribe(params => {
      this.mode = params['parameter'] ?? '';
    });

    this.sub = this.r.queryParams.subscribe(params => {
      this.authCode = params['code'] ?? '';
    });
    /*let vippLoginUrl=environment.vippsLoginUrl;
    let vippClientId= environment.vippsClientId;
    let vippRedirectUrl = environment.vippRedirectUrl;
    let ui_locale = "en"*/

    //this.document.location.href = `${vippLoginUrl}precheck/auth?client_id=${vippClientId}&redirect_uri=${vippRedirectUrl}&response_type=code&scope=profile&ui_locales=${ui_locale}`;
  }

  ngOnInit(): void {
    if (this.mode == 'noid') {
      // Redirect to external for login
      this.service.getRedirectLoginUrl().subscribe(res =>{
        if (res && res.responseData) {
          this.document.location.href = res.responseData;
        }
      });
    }
    else if (this.mode == 'url_redirect') {
      // Get authen code
      this.service.authenWithCode(this.authCode).subscribe(res =>{
        if (res && res.responseData) {
          //this.document.location.href = res.responseData;
        }
      });
    }
  }

}
