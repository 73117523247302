import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordRequest } from '@app/xModels/RegistrationUser';
import { StoreManageService } from '@app/xServices/StoreManageService.service';
import { TokenService } from '@app/xServices/token.service';
import { UserService } from '@app/xServices/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.scss']
})
export class UserPasswordComponent {
  private sub: any;
  parameter: string = "";
  mode: string = ""; 
  //1. reset - require old password
  //2. email
  //3. welcome

  isRequireCurrentPassword: boolean = false;
  isExternalReset: boolean = false;
  displayStatus: boolean = false;
  displayMessageStatus: string = '';
  isTokenValid: boolean = false;
  tempToken: string = '';
  passwordsMatching = false;
  isConfirmPasswordDirty = false;
  confirmPasswordClass = 'form-control';
  isLoading: boolean = true;

  newPassword = new FormControl(null, [
    (c: AbstractControl) => Validators.required(c),
    Validators.pattern(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_])[A-Za-z\d@$!%*?&-_]{12,}$/
    ),
  ]);
  confirmPassword = new FormControl(null, [
    (c: AbstractControl) => Validators.required(c),
    Validators.pattern(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_])[A-Za-z\d@$!%*?&-_]{12,}$/
    ),
  ]);
  currentPassword = new FormControl(null, [
    (c: AbstractControl) => Validators.required(c),
    Validators.pattern(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_])[A-Za-z\d@$!%*?&-_]{12,}$/
    ),
  ]);
  currentEmail = new FormControl(null, [
    (c: AbstractControl) => Validators.required(c), Validators.email,
  ]);

  resetPasswordForm = this.fb.group(
    {
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword,
    },
    {
      validator: this.ConfirmedValidator('newPassword', 'confirmPassword'),
    }
  );

  emailRecoveryPasswordForm= this.fb.group(
    {
      currentEmail: this.currentEmail,
    },
  );

  constructor(
    public fb: FormBuilder, 
    private userService: UserService,
    private route: ActivatedRoute, 
    private router: Router,
    private tokenService: TokenService,
    private spinner: NgxSpinnerService,
    private storeManageService: StoreManageService,
  ) {
    this.sub = this.route.params.subscribe(params => {
      this.mode = params['mode']; 
      //this.parameter = params['parameter'];
      if (this.mode == undefined || this.mode == null) {
        this.isRequireCurrentPassword = true;
        this.isExternalReset = false;
        this.resetPasswordForm = this.fb.group(
          {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword,
            confirmPassword: this.confirmPassword,
          },
          {
            validator: this.ConfirmedValidator('newPassword', 'confirmPassword'),
          }
        );
      }
      else if (this.mode == 'email') {
        this.isExternalReset = false;
      }
      else {
        this.isExternalReset = true;
      }
    });

    this.parameter = this.route.snapshot.queryParamMap.get('code') ?? '';
  }

  ngOnInit(): void { 
   
    if (this.isExternalReset) {
      this.spinner.show();
      this.tokenService.VerifyAccessLik(this.parameter).subscribe({
        next: (x: any) => {
          if(x.responseData && x.responseData != '') {
            this.tempToken = x.responseData;
            this.isTokenValid = true;
          }
          else {
            this.isTokenValid = false;
          }
          this.isLoading = false;
          this.spinner.hide();
         },
        error: (error: any) => {
          console.log(error);
          this.isLoading = false;
          this.spinner.hide();
         },
      });
    }
    else {
      this.isLoading = false;
    }
  }

  onSubmitResetPassword(): void {
    console.log(this.resetPasswordForm);
    if (!this.resetPasswordForm?.valid) {
      return;
    }

    this.spinner.show();
    var request:ResetPasswordRequest = {
      currentPassword: this.resetPasswordForm?.value?.currentPassword,
      newPassword: this.resetPasswordForm?.value?.newPassword,
      requireVerifyCurrentPassword: this.isRequireCurrentPassword,
      tokenCode: this.isTokenValid ? this.tempToken : ''
    };

    this.userService.ChangePassword(request).subscribe(
    {
      next: (data) => {
        if (data.responseData && data.responseData.accessToken) {
          this.tokenService.ResetContactStorage();
          this.tokenService.SetToken(data.responseData.accessToken);

          const contactId = this.tokenService.GetIdentity().ContactId;
          this.storeManageService.AddContactId(contactId);

          this.spinner.hide();
          this.router.navigate(["/inbox"], { relativeTo: this.route });
        }
        else if (data.description == 'INVALID_TOKEN') {
          this.displayStatus = true;
          this.displayMessageStatus = "Din personlige informasjon mangler.";
        }
        else if (data.description == 'INVALID_PASSOWRD') {
          this.displayStatus = true;
          this.displayMessageStatus = "Passordet ditt er feil";
        }
        else {
          this.displayStatus = true;
          this.displayMessageStatus = "Det har oppstått en feil. Vennligst kontakt administrator";
        }
        this.spinner.hide();
      },
      error: (err) => {
        this.displayStatus = true;
        this.displayMessageStatus = "Det har oppstått en feil. Vennligst kontakt administrator";
      }
    });
  }

  onSubmitEmailRequest(): void {
    this.displayStatus = false;
    console.log(this.emailRecoveryPasswordForm);
    if (!this.emailRecoveryPasswordForm?.valid) {
      return;
    }

    this.spinner.show();
    this.userService.RequireResetpassword(this.emailRecoveryPasswordForm?.value.currentEmail!).subscribe(
    {
      next: (data) => {
        if (data.responseData && data.responseData != '') {
          console.log(data.responseData);
          this.router.navigate(["/registration/result/sendforgetmail"]);
        }
        else if (data.description == 'DATA_MISSING') {
          this.displayStatus = true;
          this.displayMessageStatus = "Vi fant ikke denne e-posten: " + this.emailRecoveryPasswordForm?.value.currentEmail! + " på systemet.";
        }
        else {
          this.displayStatus = true;
          this.displayMessageStatus = "Det har oppstått en feil. Vennligst kontakt administrator";
        }
        this.spinner.hide();
      },
      error: (err) => {
        this.displayStatus = true;
        this.displayMessageStatus = "Det har oppstått en feil. Vennligst kontakt administrator";
      }
    });
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
