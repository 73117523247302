import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { IPicklistCategories } from '@app/xModels/CrmModel';
import { Membership } from '@app/xModels/Membership';
import { RegistrationUser, RegistrationWorkplace } from '@app/xModels/RegistrationUser';
import { UserStatusModel } from '@app/xModels/UserStatusModel';
import { CrmService } from '@app/xServices/crm.service';
import { DecryptPipe, EncryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { TokenService } from '@app/xServices/token.service';
import { UserService } from '@app/xServices/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-registration-update',
  templateUrl: './registration-update.component.html',
  styleUrls: ['./registration-update.component.scss']
})
export class RegistrationUpdateComponent implements OnInit {

  public newsSubscription: boolean = true;
  private personalId: string = '';
  private personalDataBID: UserStatusModel = new UserStatusModel();

  private personalCGUID: string = "";  // personal CRM ID GUID
  public updateUserForm!: UntypedFormGroup;
  public MembershipList: Membership[] = [];
  countryPicklist: IPicklistCategories[] = [];
  businessSectorPicklist: IPicklistCategories[] = [];

  public existUser: boolean = true;
  public statusType: boolean = false;
  private NewUser: RegistrationUser = new RegistrationUser();
  public disableSubmitBtn : boolean = false;
  membershipService: any;



  constructor(public fb: UntypedFormBuilder, private userService: UserService,
    private crmService: CrmService,
    private decryptPipe: DecryptPipe,

    private spinner: NgxSpinnerService,
    private router: Router,
    private tokenService: TokenService,
    private r: ActivatedRoute) {



    /* ----------------- Responce to CRM by GUID and fill fields ---------------- */
    try {
      let retrievedObjectGUID = localStorage.getItem("adamCGUID"); //adamCGUID - CRM Guid
      let decryptedGUID = this.decryptPipe.transform(retrievedObjectGUID!);
      this.personalCGUID = JSON.parse(decryptedGUID!);

    } catch (error) {
      console.error("Not allowed to get GUID data");
    }
    /* ---------------------------------------------------------------------- */

  }

  ngOnInit(): void {
    this.spinner.show();

    this.crmService.GetPicklistValues("contact", "acan_land").subscribe({
      next: (data: IPicklistCategories[]) => {
        this.countryPicklist = data;       
      }
    });

    this.crmService.GetPicklistValues("contact", "acan_bransje").subscribe({
      next: (businessSectorData: IPicklistCategories[]) => {
        this.businessSectorPicklist = businessSectorData;        
      }
    });

    this.updateUserForm = this.fb.group({
      DNumber: ['', [Validators.required, Validators.pattern(/^\s?\S+[a-zA-Z0-9_-\s+]{8,16}$/)]], //  length from 8 to 16 characters],
      firstName: ['', [Validators.required, Validators.pattern(/^[^<>*!?,%()="'`{|}~;/$^]{2,35}$/)]], //  start with letter[length from 2 to 35 characters]],\\
      lastName: ['', [Validators.required, Validators.pattern(/^[^<>*!?,%()="'`{|}~;/$^]+$/)]], //  start with letter],
      userPhone: ['', [Validators.required, Validators.pattern(/^[^a-zA-Z<>*!?,%{}@+="'`~;:\/#/|$&^]+$/)]], //  @#$%^&_+=-"'|\?/.~`
      country: [Validators.required, { disabled: false }],
      //,
      city: [{ value: '', disabled: false }],
      zipCode: ['', [Validators.required, Validators.pattern(/^\s?\S+[a-zA-Z0-9_-\s+]{2,9}$/)]], //  length from 3 to 9 characters],
      locationOrStreetName: ['', [Validators.required, Validators.pattern(/^[^<>*!?,%()="'`{|}~;/$^]{2,28}$/)]], //  start with letter[length from 4 to 28 characters]], old RegExp --> (/^\s?\S+[a-zA-Z0-9_./-\s+]{1,28}$/)
      userEmail: ['', [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)]],
      newsletter:[''],

      brunch: [''],
      enlistedBy: [''],
      privacy: [false, [Validators.requiredTrue]],


    });


    if (this.personalCGUID !== "" && this.personalCGUID.length > 30) {

      this.GetDataFromBankId();
      this.GetUserInfoFromCRM(this.personalCGUID);
      this.existUser = false;

    }
    this.IsInputNotEmpty();
    this.spinner.hide();
  }

  get f(){

    return this.updateUserForm.controls;

  }

  private IsInputNotEmpty() {
    if (this.personalDataBID.givenName !== null || this.personalDataBID.givenName !== undefined) {
      this.updateUserForm.controls['firstName'].setValue(this.personalDataBID.givenName, { onlySelf: true });
      this.updateUserForm.controls['lastName'].setValue(this.personalDataBID.familyName, { onlySelf: true });
      this.updateUserForm.controls['country'].setValue(this.countryPicklist[1], { onlySelf: true });
    }
  }


  /* -------------------------------------------------------------------------- */
  /*          Get data from CRM for existing users who login first time         */
  /* -------------------------------------------------------------------------- */

  private GetUserInfoFromCRM(crmGUID: string) {

    this.userService.GetUserInfoFromCRMByGIUD(crmGUID).subscribe({
      next: (value: RegistrationUser) => {       
        this.NewUser = value;
        this.tokenService.SetToken(value.accessToken)
      },
      error: (err) => {
        this.statusType = false;
        console.error("errorMessage: " + err);
      },
      complete: () => {
        let active = new Date().toISOString();
        if (this.NewUser.ctg_invitationvalidto < active || this.NewUser.ctg_invitationvalidto == null) {
          this.statusType = true;
          console.log("Date result: " + this.NewUser.ctg_invitationvalidto < active);
        } else {


          this.updateUserForm.controls['city'].setValue(this.NewUser.address1_city, { onlySelf: true });
          this.updateUserForm.controls['country'].setValue(this.NewUser.acan_land, { onlySelf: true });
          if (this.NewUser.mobilephone !== null && this.NewUser.mobilephone !== undefined) this.NewUser.mobilephone = this.NewUser.mobilephone.substring(4);
          this.updateUserForm.controls['userPhone'].setValue(this.NewUser.mobilephone, { onlySelf: true });
          this.updateUserForm.controls['zipCode'].setValue(this.NewUser.address1_postalcode, { onlySelf: true });
          this.updateUserForm.controls['locationOrStreetName'].setValue(this.NewUser.address1_line1, { onlySelf: true });
          this.updateUserForm.controls['userEmail'].setValue(this.NewUser.emailaddress1, { onlySelf: true });
          try {
            this.newsSubscription=this.NewUser.acan_nyhetsbrev;
            this.updateUserForm.controls['newsletter'].setValue(this.newsSubscription, { onlySelf: true });
          } catch { }


          if (this.NewUser.acan_bransje)
            this.updateUserForm.controls['brunch'].setValue(this.NewUser.acan_bransje, { onlySelf: true });
          this.NewUser.ctg_personid = this.personalId; // Text
          this.updateUserForm.controls['DNumber'].setValue(this.NewUser.acan_fodselsnummer, { onlySelf: true });

        }
      }
    });
  }


  public onSubmit() {

    this.spinner.show();
    this.disableSubmitBtn = true;
    
    if (this.personalCGUID !== "" && this.personalCGUID.length > 30) {

      this.NewUser.address1_city = this.updateUserForm.value.city;
      this.NewUser.acan_land = this.updateUserForm.value.country;
      this.NewUser.firstname = this.updateUserForm.value.firstName;
      this.NewUser.lastname = this.updateUserForm.value.lastName;
      this.NewUser.mobilephone = "0047" + this.updateUserForm.value.userPhone;
      this.NewUser.address1_postalcode = this.updateUserForm.value.zipCode.toString();
      this.NewUser.address1_line1 = this.updateUserForm.value.locationOrStreetName;
      this.NewUser.emailaddress1 = this.updateUserForm.value.userEmail;     
      this.NewUser.acan_nyhetsbrev=  this.newsSubscription;
     


      if (this.updateUserForm.value.brunch)
        this.NewUser.acan_bransje = this.updateUserForm.value.brunch;

      this.NewUser.ctg_personid = this.personalId; // Text

      //TODO get fodselsnummer from BankId
      this.NewUser.acan_fodselsnummer = this.updateUserForm.value.DNumber.toString(); // Text
      this.userService.UpdateRegistration(this.NewUser).subscribe({
        next: (data) => {
          this.statusType = false;
          this.tokenService.SetToken(data.responseData);
          this.spinner.hide();
        },
        error: (err) => {
          this.spinner.hide();
          this.statusType = true;
          console.error("errorMessage: " + err);

        },
        complete: () => {
          try {
            localStorage.removeItem("adamCGUID");
            this.router.navigate(["/inbox"], { relativeTo: this.r });
            this.disableSubmitBtn = false;
          } catch (error) {
            console.error("Can not set userCrmId to cache")
            this.disableSubmitBtn = false;
          }

        },
      });
      this.spinner.hide();
    }
  }

  public Redirect() {
    this.router.navigate(["/login"], { relativeTo: this.r });
  }

  public changeSubscribe(e:any){    
    console.log("form is valid:" + this.updateUserForm.valid);
    this.newsSubscription = (e.target.value =="true");
  }
  private GetDataFromBankId() {
    try {
      let retrievedObjectBID = localStorage.getItem("adamBID");
      let decryptedDataBID = this.decryptPipe.transform(retrievedObjectBID!);
      this.personalDataBID = JSON.parse(decryptedDataBID!);
      this.personalId = this.personalDataBID.personID;
    } catch (error) {
      console.error("Not allowed to get BID data");
    }
  }


}
