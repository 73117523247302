import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NavigationEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import { RegistrationComponent } from './registration/registration.component';
import { DecryptPipe } from './xServices/enctypt-decrypt.pipe';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Medlems Portal';
  withoutRootComponents: boolean = false;

  public constructor(private activatedRoute: ActivatedRoute, 
    private decryptPipe: DecryptPipe, 
    private router: Router,
  ) {
    this.withoutRootComponents = activatedRoute.snapshot.data['showRootComponents'];
  }
}


@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }
  transform(url: any) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

}