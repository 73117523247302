import { Pipe, PipeTransform } from '@angular/core';
import * as CryptoJS from 'crypto-js';


//TODO create account in password manager
const key = '3#K=EtTEA_n6-gSz';

@Pipe({name: 'encrypted'})
export class EncryptPipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
      return CryptoJS.AES.encrypt(value, key).toString();
    }
  }
}

@Pipe({name: 'decrypted'})
export class DecryptPipe implements PipeTransform {
  transform(encrypted: string):any {
    if (encrypted) {
      const decrypted = CryptoJS.AES.decrypt(encrypted.toString(), key);
      return decrypted.toString(CryptoJS.enc.Utf8);
    }
  }
}
