
<div class="container h-75">
  <div class="course-registered" *ngIf="registeredDatas && registeredDatas.length > 0">
    <div class="row">
      <div class="col-12 bg-primary-header  mt-5  text-start ps-4">
        <p class="fs-4 pt-3 fw-normal">Dine påmeldinger</p>
      </div>
    </div>
    <div class="row">
      <div class="category-container">
        <div class="row mb-4">
          <div class="col-xs-12 col-md-4" *ngFor="let course of registeredDatas">
            <div class="color-block-wrapper">
              <div class="color-block color-block-icon-list color-block-green">
                <div class="color-block-head">
                  {{ displayDateRange(course) }}
                </div>
                <div class="color-block-text">
                  {{ course.courseName }}
                </div>
              </div>
              <div class="color-block-bottom text-right">
                <a class="btn btn-danger" (click)="cancelCourseRegister(course.id)">Annuler påmelding</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  

  <div class="course-upcoming" *ngIf="availableDatas && availableDatas.length > 0">
    <div class="row">
      <div class="col-12 bg-primary-header  mt-5  text-start ps-4">
        <p class="fs-4 pt-3 fw-normal">Våre kurs</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <div class="filter-container">
          <div class="filter-item p-2">
            <label>Kategori</label>
            <select  (change)="filterCategoryChange($event.target)">
              <option *ngFor="let opt of categoryOption" [value]="opt.value">{{opt.label}}</option>
            </select>
          </div>
          <div class="filter-item p-2">
            <label>Semester</label>
            <select (change)="filterSemesterChange($event.target)">
              <option *ngFor="let opt of semeterOption" [value]="opt.value">{{opt.label}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="category-container">
        <div class="row mb-4">
          <div class="col-xs-12 col-md-4" *ngFor="let course of renderCourse()">
            <div class="color-block-wrapper">
              <div class="color-block color-block-icon-list color-block-dblue">
                <div class="color-block-head">
                  {{ displayDateRange(course) }}
                </div>
                <div class="color-block-text">
                  {{ course.courseName }}
                </div>
              </div>
              <div class="color-block-bottom text-right">
                <a [routerLink]="['/course', 'info', course.id ]" class="btn btn-transparent-lblue">Informasjon</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Laster... </p>
</ngx-spinner>
