import { JsonPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { IMembership } from '@app/xModels/Membership';
import { RegistrationResponse, RegistrationUser, ResetPasswordRequest, UpdateUser, UpdateUserInfoResponse } from '@app/xModels/RegistrationUser';
import { UserContacts } from '@app/xModels/UserContacts';
import { Workplace, Workplaces } from '@app/xModels/WorkingInfo';
import { catchError, map, Observable, throwError } from 'rxjs';
import { CrmService } from './crm.service';
import { BaseModel, OptionSetItem } from '@app/xModels/BaseModel';

@Injectable({
  providedIn: 'root'
})
export class UserService {



  constructor(private crmService: CrmService) { }

  public GetUserInfo(contactId: string) {
    //let endpoint = "contacts({contactId)?$select=firstname,lastname,emailaddress1,emailaddress2,mobilephone,telephone1,telephone2,address1_line1,address1_postofficebox,address1_postalcode,address1_city,acan_land";
    const endpoint = "UserInfo/Existing/" + contactId;
    return this.crmService.get<BaseModel<UserContacts>>(endpoint)
  }

  // RegistrationUser
  public GetUserInfoFromCRMByGIUD(contactId: string) {
    const _endpoint = "userInfo/Existing/" + contactId;
    //let _endpoint = "contacts(" + contactId + ")?$select=contactid,address1_city,acan_land,mobilephone,address1_postalcode,address1_line1,emailaddress1,acan_bransje,acnd_arbeidsgiverfraweb,acan_nyhetsbrev,ctg_personid,acan_fodselsnummer,ctg_invitationvalidto&$expand=membershipRegistration($select=fromDate,membershipId,acan_tildato)";
    return this.crmService.get<BaseModel<RegistrationUser>>(_endpoint)
      .pipe(
        map(data => {
          let responseData = data.responseData;
          if (responseData.membershipRegistration !== undefined  && responseData.membershipRegistration !== null) {
            // delete data['@odata.context'];
            // delete data['@odata.etag'];

            /* ---------- We process the array. -------------------------------------------------------------- */
            /* ---------- Compare the dates and choose the most recent date for membership registration. ----- */
            /* ---------- We delete all elements of the array except for one actual one. --------------------- */
            /*if (responseData.membershipRegistration.length == 1) {
              //  delete data['@odata.etag'];
              //responseData.membershipRegistration[0].acan_vare = responseData.membershipRegistration[0].membershipId;
            }
            else {
              try {
                responseData.membershipRegistration = responseData.membershipRegistration;
                //.sort((a: { fromDate: string | number | Date; }, b: { fromDate: string | number | Date; }) => new Date(b.fromDate).getTime() - new Date(a.fromDate).getTime())[0];
                //responseData.membershipRegistration[0].acan_vare = responseData.membershipRegistration[0].membershipId;
                // delete data.membershipRegistration[0].membershipId
                // delete data.membershipRegistration[0]['@odata.etag'];

              } catch { }
            }*/
          }
          return responseData;
        })
      );

  }

  public Registration(user: RegistrationUser) {
    // select=firstname,lastname,emailaddress1,emailaddress2,mobilephone,telephone1,telephone2,address1_line1,address1_postofficebox,address1_postalcode,address1_city,acan_land";
    let sendData = JSON.stringify(user);
    return this.crmService.post<BaseModel<RegistrationResponse>>("Registration/New", sendData);
  }

  public RequireResetpassword(email: string) {
    return this.crmService.post<BaseModel<string>>("Registration/EmailResetPasswordLink", JSON.stringify({ RecoveryPasswordEmail: email }));
  }

  public RequireWelcomeVerifyEmail(contactId: string) {
    return this.crmService.post<BaseModel<string>>("Registration/EmailWelcomeVerifyLink", JSON.stringify({ contactId: contactId }));
  }

  public UpdateRegistration(updatedInfo: RegistrationUser) {
    delete updatedInfo.membershipRegistration;
    let url = "Registration/update";
    return this.crmService.patch<BaseModel<string>>(url, updatedInfo);
  }

  public UpdateContactInfo(updatedInfo: UpdateUser) {
    if (updatedInfo.membershipRegistration)
      delete updatedInfo.membershipRegistration;
    return this.crmService.patch<BaseModel<UpdateUserInfoResponse>>("UserInfo/Update", updatedInfo);
  }

  public ChangePassword(data: ResetPasswordRequest) {
    return this.crmService.post<BaseModel<RegistrationResponse>>("Registration/ChangePassword", data);
  }
  
  /**
  * Either "showonsignup" or "showonchange"
  *
  * Retrieves a list of memberships
  */
  public GetMembershipList(type: string) {
    var filterShowOn: string = "";
    if (type == "showonsignup") {
      filterShowOn = "OnSignUp";
    }
    else if (type == "showonchange") {
      filterShowOn = "OnChange";
    }

    let endpoint = "Membership/filter/" + filterShowOn;
    return this.crmService.get< BaseModel<IMembership[]>>(endpoint);
  }

  public GetMembershipCurrentList() {
    let endpoint = "Membership/CurrentList";
    return this.crmService.get<any>(endpoint)
      .pipe(
        map(data => {
          var finalData = data.replace(/\\/g, "");
          delete finalData['@odata.context'];
          delete finalData.value['@odata.etag'];

          let result: IMembership[] = finalData.value;
          return result;
        })
      );
  }

  //#region WORKPLACE
  public GetAllWorkplaces(contactId: string) {
    let endpoint = "Workplace/list/" + contactId;
    return this.crmService.get<BaseModel<Workplace[]>>(endpoint);
  }

  public endWorkplace(oldWorkplaceId: string) {
    let endpoint = "Workplace/end/" + oldWorkplaceId;
    return this.crmService.patch(endpoint, null)
  }

  public addWorkplace(workplaceName: string, workplaceType: number, contactId: string) {
    var record = {
    ctg_name: workplaceName, // Text
    ctg_datefrom: new Date().toISOString(), // Date Time
    ctg_type : workplaceType, // Choice
    _ctg_ContactId_value:  contactId // Lookup
   };

    var data = JSON.stringify(record);
     let endpoint = "Workplace/new";
    return this.crmService.post(endpoint, data);
  }
  //#endregion WORKPLACE
  
  //#region EDUCATION
  public GetAllEducations(contactId: string) {
    let endpoint = "Education/list/" + contactId;
    return this.crmService.get<BaseModel<Workplace[]>>(endpoint);
  }

  public endEducation(oldWorkplaceId: string) {
    let endpoint = "Education/end/" + oldWorkplaceId;
    return this.crmService.patch(endpoint, null)
  }

  public addEducation(workplaceName: string, workplaceType: number, contactId: string) {
    var record = {
    ctg_name: workplaceName, // Text
    ctg_datefrom: new Date().toISOString(), // Date Time
    ctg_type : workplaceType, // Choice
    _ctg_ContactId_value:  contactId // Lookup
   };

    var data = JSON.stringify(record);
     let endpoint = "Education/new";
    return this.crmService.post(endpoint, data);
  }
  //#endregion EDUCATION

  public getInvoiceData() {
    var parameters: any = {};
     parameters.type = "json"; // Edm.String
    // parameters.url = "v2/invoice?invoiceDateFrom=2022-01-01&invoiceDateTo=2023-12-12&customerId=47219998&from=0&count=1000&sorting=-invoiceNumber"; // Edm.String
    // let endpoint = "/ctg_GetInvoiceData";
    let url = "Invoice";
    var body = JSON.stringify(parameters);
    return this.crmService.post<any>(url, body).pipe(
      map(
        (data: any) => {
          let jsonData = JSON.parse(data);
          let jsonDataValue = JSON.parse(jsonData.data)
          return jsonDataValue;
        })
    );;
  }
  public downloadInvoice(invoiceNr: string) {
    var parameters: any = {};
    let url = "Invoice/Download/?invoiceNr=" + invoiceNr;
    var body = JSON.stringify(parameters);
    return this.crmService.getBlob(url, "post", body).pipe(
      map(
        (_blob : any) => {
          // const url = window.URL.createObjectURL(
          //   new Blob(_blob, { type: _blob.type })
          // );
          // window.open(url, '_blank');
          const anchor = window.document.createElement('a');
          anchor.href = window.URL.createObjectURL(_blob);
          anchor.download = "faktura_" + invoiceNr + ".pdf";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(anchor.href);
        }
    ));
    
  }

  public getInvoiceOption(): Observable<OptionSetItem[]> {
    return this.crmService.get<BaseModel<Array<OptionSetItem>>>("UserInfo/InvoiceOption").pipe(
      map(
        data => {
          return data.responseData;
        })
    );
  }
}

