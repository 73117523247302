import { UserIdentity } from './../xModels/UserIdentity';
import { EventEmitter, Injectable, Output } from '@angular/core';
//import  moment from "moment";
import * as moment from 'moment'
import 'moment-timezone';
import { first, retry } from 'rxjs/operators';

import { RootService } from './root.service';
import { UserRefreshTokenModel } from '@app/xModels/UserRefreshTokenModel';
import { ServerToken } from '@app/xModels/ServerToken';
import { Observable } from 'rxjs';
import { BaseModel } from '@app/xModels/BaseModel';
import { StoreManageService } from './StoreManageService.service';



@Injectable({
  providedIn: 'root'
})
export class TokenService {
  @Output() SendLogInStatusEvent = new EventEmitter<boolean>();

  constructor(private httpRootAuth: RootService,private storeManageService:StoreManageService) {
    this.setDefaultTimezone();
  }

  public GetNewToken(personId:string) {
    return this.httpRootAuth.get<BaseModel<ServerToken>>("auth/token/" + personId);
  };

  private setDefaultTimezone() {
    moment.tz.setDefault('Etc/UTC');
  }

  public SetToken(accessToken: string) {
    localStorage.setItem('accessToken', accessToken);
  }

  public SetRefreshToken(refreshToken: string) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  // Checking if token is not expired
  public IsAuthorized() {
    let getDate: moment.Moment = this.getExpiration();
    console.log("Token.exp * 1000 Resultat :"+getDate.format());
    let actualTime = moment().utc();
    let ch = actualTime.isBefore(getDate);
    if (!ch) {

      let G = moment(getDate);
      console.log("Data JWT format : "+G.format());
      let N =moment(actualTime);
      console.log("Data NOW format : "+N.format());
      this.ClearlocalStorage();
    }
    this.SendLogInStatusEvent.emit(ch);
    return ch;
  }

  private getExpiration() {

    let expiration: any;
    try {
      let _token = localStorage.getItem("accessToken")?.toString()!;

      //Get expiresOn from token
      let decodedToken = this.TokenDecode(_token);
      expiration= new Date(decodedToken.exp * 1000) ;    
      let result = moment.utc(expiration);     
      return result;
    } catch (e) {
      console.log("error decoding token" + e);
      let timeNow_1 = moment().utc().add(-5, 'minute');
      console.log("timeNow_1 format :"+timeNow_1.format());
      return timeNow_1;
    }
  }

  private TokenDecode(token: string) {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      console.log("error decoding token");
    }
  }

  public RefreshToken(): Observable<any> {
    let token: any = localStorage.getItem('accessToken')?.toString();
    let refreshToken: any = localStorage.getItem('refreshToken')?.toString();

    return this.httpRootAuth.post<any>('auth/refreshtoken', 
      {
        accessToken: token,
        refreshToken: refreshToken,
      }
    );
  }

  /**
   * GetIdentity
   */
  public GetIdentity(): UserIdentity {

    let token: any = localStorage.getItem('accessToken')?.toString();

    let _identity: UserIdentity = new UserIdentity();

    if (token == null) {
      return _identity;
    }
    let decodedToken = this.TokenDecode(token);

    
   // localStorage.setItem("adamId",decodedToken.ContactId);
    
    _identity.ContactId = decodedToken.ContactId;
    _identity.Tripletext = decodedToken.prn;
    
    return _identity;
  }

 
  public ResetContactStorage() {
    try {

      this.storeManageService.ResetState('contactId'); 
      
      return true;

    } catch (error) {
      return false;
    }
  }
  
  /**
   * ClearlocalStorage
   */
  public ClearlocalStorage() {
    try {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("adam");
      localStorage.removeItem("adamBID");
     // localStorage.removeItem("adamId");
      localStorage.removeItem("adamUCI");
      localStorage.removeItem("expires_at");
      this.storeManageService.ResetState('contactId'); 
      
      return true;

    } catch (error) {
      return false;
    }
  }

  public UserLogin(username: string, password: string): Observable<BaseModel<ServerToken>> {
    return this.httpRootAuth.post<BaseModel<ServerToken>>('auth/Login', 
      {
        Username: username,
        Password: password,
      }
    );
  }

  public VerifyAccessLik(code: string): Observable<BaseModel<string>> {
    return this.httpRootAuth.post<BaseModel<string>>('auth/VerifyAccessLik', 
      {
        Accesskey: code,
      }
    );
  }
}
