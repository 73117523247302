<div class="container-bg-color pt-5">
  <main class="container bg-white ps-5 pe-5">
    <div class=" mb-3 row ">
      <div class="col-12 mt-5  ">
        <h3>Innmelding </h3>
      </div>
    </div>

    <ngb-alert type="danger" *ngIf="statusType" [dismissible]="false" class="text-center">
      <label [innerHTML]="displayMessageStatus"></label>
    </ngb-alert>
 

    <div class="row justify-content-center " *ngIf="newUserForm">
      <form [formGroup]="newUserForm" class="form-container" autocomplete="on">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-sm-12  ">
            <label for="given-name" class="form-label form-general-lable mt-3">Fornavn</label><span class="text-danger star"> *</span>
            <input type="text" class="form-control" formControlName="firstName" autocomplete="given-name"
              id="given-name" name="given-name"
              [ngClass]="{ 'is-invalid ':newUserForm.get('firstName')?.touched && newUserForm.get('firstName')?.invalid,  'is-valid ':newUserForm.get('firstName')?.touched && newUserForm.get('firstName')?.valid}">

          </div>
          <div class="col-md-6 col-lg-6 col-sm-12">
            <label for="family-name" class="form-label form-general-lable mt-3">Etternavn</label><span class="text-danger star"> *</span>
            <input type="text" class="form-control" formControlName="lastName" autocomplete="family-name"
              id="family-name" name="family-name" placeholder=""
              [ngClass]="{ 'is-invalid ':newUserForm.get('lastName')?.touched && newUserForm.get('lastName')?.invalid,  'is-valid ':newUserForm.get('lastName')?.touched && newUserForm.get('lastName')?.valid}">

          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-lg-6 col-sm-12">
            <label for="email" class="form-label form-general-lable mt-3">E-post</label><span class="text-danger star"> *</span>
            <input type="email" class="form-control" formControlName="userEmail" id="email" placeholder=""
              autocomplete="email" name="email"
              [ngClass]="{ 'is-invalid ':newUserForm.get('userEmail')?.touched && newUserForm.get('userEmail')?.invalid,  'is-valid ':newUserForm.get('userEmail')?.touched && newUserForm.get('userEmail')!.valid}">
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12" *ngIf="mode != 'member'">
            <label for="tel" class="form-label form-general-lable mt-3">Mobil</label><span class="text-danger star"> *</span>
            <div class="input-group flex-nowrap">
              <span class="input-group-text" id="addon-wrapping">+47</span>
              <input type="tel" maxlength="8" class="form-control" autocomplete="tel" formControlName="userPhone" id="tel" name="tel"
                placeholder=""
                [ngClass]="{ 'is-invalid ':newUserForm.get('userPhone')?.touched && newUserForm.get('userPhone')?.invalid,  'is-valid ':newUserForm.get('userPhone')?.touched && newUserForm.get('userPhone')!.valid}">
            </div>
          </div>
        </div>
        <div class="row">       
         
        </div>
        
        <div class="row">
          <div class="col-md-6 col-lg-6 col-sm-12"  *ngIf="mode != 'member'">
            <label for="nInputNumber" class="form-label form-general-lable mt-3">Fødsels/D-number</label><span class="text-danger star">
              *</span>
            <input type="number" class="form-control" formControlName="DNumber" id="nInputNumber" placeholder=""
              maxlength="16" autocomplete="on"
              [ngClass]="{ 'is-invalid ':newUserForm.get('DNumber')?.touched && newUserForm.get('DNumber')?.invalid,  'is-valid ':newUserForm.get('DNumber')?.touched && newUserForm.get('DNumber')?.valid}">

          </div>
          <div class="col-md-6 col-lg-6 col-sm-12">
            <label for="nInput3" class="form-label form-general-lable mt-3">Send faktura via </label><span class="text-danger star">
              *</span>
            <select class="form-select" formControlName="sendFakturaVia" id="nInput3">
              <option *ngFor="let opt of invoiceOption" [value]="opt.value">{{opt.label}}</option>
            </select>
          </div>
        </div>
       

        <div class="row">
          <div class="col-lg-6  col-md-6 col-sm-12">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12  ">
                <h5 class=" mt-4 mb-4">Medlemskap </h5>
                <select class="form-select" formControlName="membershipType" id="nInputNewMember">
                  <option *ngFor="let membership of MembershipList" [value]="membership.acan_vareid">
                    {{membership.acan_name}}
                  </option>
                </select>
              </div>
            </div>
            <ng-container  *ngIf="isMandatorySpecificName">  
            <div class="row" >     
              <div class="col-md-6 col-lg-6 col-sm-12">
                <label for="nInputJobb" class="form-label mt-3">Arbeidsgiver / Studiested</label><span class="text-danger star">
                  *</span>
                <input type="text" class="form-control" formControlName="employer" id="nInputJobb" placeholder=""
                  maxlength="50" autocomplete="on"
                  [ngClass]="{ 'is-invalid ':newUserForm.get('employer')?.touched && newUserForm.get('employer')?.invalid,  'is-valid ':newUserForm.get('employer')?.touched && newUserForm.get('employer')?.valid}">
                 
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">            
                  <label for="nInputYrke" class="form-label mt-3">Type</label><span class="text-danger star"> *</span>
                  <select class="form-select" formControlName="employerType" id="nInputYrkeType">
                    <option *ngFor="let type of workplaceTypePicklist" [value]="type.value">
                      {{type.label}}
                    </option>
                  </select>
                </div>                      
            </div>  
          </ng-container>    
          </div>
          <div class="col-lg-6  col-md-6 col-sm-12 mt-4" *ngIf="mode != 'member'">
            <h5 class="mb-4">Nyhetsbrev </h5>
            <div class="col-lg-12  col-md-12 col-sm-12">
            <div class="form-check mb-2 ">
              <input class="form-check-input big-checkbox" type="checkbox" value="" id="consentChoiceEmail" formControlName="consentChoiceEmail">
              <label class="  ms-2 mb-2 checkbox-label" for="flexCheckDefault">
                Jeg vil gjerne motta nyheter per e-post
              </label>            
            </div>
         
            <div class="form-check">
              <input class="form-check-input big-checkbox " type="checkbox" value="" id="consentChoiceSMS" formControlName="consentChoiceSMS">
              <label class="  checkbox-label ms-2" for="flexCheckChecked">
                Jeg vil gjerne motta nyheter per sms
              </label>
            </div>
          </div>
          </div>
          <!--div class="col-lg-6  col-md-6 col-sm-12 mt-4" *ngIf="mode != 'member'">
            <h3>Nyhetsbrev </h3>
            <div class="row d-flex justify-content-evenly mt-4">
              <div class="col-md-2 col-sm-6">
                <div class="form-check  ">
                  <input class="form-check-input form-control-md" type="radio" [value]="newsSubscription" formControlName="newsletter"
                    name="newsletter" id="flexRadioDefault1" checked required >
                  <label class="form-check-label custon-check-label" for="flexRadioDefault1" required >
                    <strong >Ja</strong>
                  </label>
                </div>
              </div>
              <div class="col-md-10 col-sm-6">
                <div class="form-check ">
                  <input class="form-check-input" type="radio" [value]="!newsSubscription" formControlName="newsletter"
                    name="newsletter" id="flexRadioDefault2" required>
                  <label class="form-check-label custon-check-label " for="flexRadioDefault2">
                    <strong> Nei</strong>
                  </label>              
                </div>
              </div>
            </div>
          </div-->
        </div>
        
        <div class="row" *ngIf="mode != 'member'">
          
        </div>

        <div class="row mt-5">
          <div class="col-lg-12  col-md-12 col-sm-12">
            <div class="form-check mb-2 ">
              <input class="form-check-input big-checkbox" type="checkbox" value="" id="flexCheckDefault" formControlName="privacy">
              <label class="  ms-2 mb-2 checkbox-label fw-semibold" for="flexCheckDefault">
                Samtykke personvern
              </label>            
              <a class="text-decoration-none checkbox-label" href="https://krifa.no/behandling-av-personopplysninger/" target="_blank"
                rel="noopener noreferrer"> Her kan du lese mer om hvordan vi behandler dine
                <strong>personopplysninger.</strong></a>
            </div>
         
            <div class="form-check">
              <input class="form-check-input big-checkbox " type="checkbox" value="" id="flexCheckChecked"
                formControlName="agreement">
              <label class="  checkbox-label ms-2 fw-semibold" for="flexCheckChecked">
                Jeg aksepterer medlemsbetingelser
              </label>
              <a class="text-decoration-none checkbox-label" href="https://www.krifa.no/om-krifa/medlemsbetingelser/" target="_blank"
                rel="noopener noreferrer"> Her kan du lese mer om våre <strong>medlemsbetingelser</strong>.</a>
            </div>
          </div>
        </div>

        <hr class="mt-4 mb-4 ">
        <div class="row mt-5  justify-content-end">         
          <div class="col-5 mb-5 text-end">
            <button class="btn btn-primary rounded-4  text-nowrap" id="btnSubmit" type="submit" [disabled]="!newUserForm.valid || disableSubmitBtn"
              (click)="onSubmit()">Gå videre</button>
          </div>
        </div>
      </form>
    </div>
  </main>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Laster... </p>
</ngx-spinner>