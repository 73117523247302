import { Injectable } from '@angular/core';
import { AddContactIdAction } from '@app/store/contact-id/contact-id.actions';
import { ContactIdSelectors } from '@app/store/contact-id/contact-id.selector';
import { AddUserInfoAction } from '@app/store/user-info/user-info.actions';
import { UserInfoSelectors } from '@app/store/user-info/user-info.selector';
import { UserContacts } from '@app/xModels/UserContacts';

import { Store } from '@ngxs/store';






@Injectable({ providedIn: 'root' })


export class StoreManageService {

    constructor(private store: Store) { }



    public AddNewUserInfoState(userInfoModel: UserContacts) {
        this.store.dispatch(new AddUserInfoAction(userInfoModel));
    }

    public ResetState(stateName: string) {
        this.store.reset(stateName);
    }

    public GetUserData(): UserContacts {
        try {
            let resultOfUser = this.store.selectSnapshot(UserInfoSelectors.getUserInfoItems);
            if (resultOfUser==undefined) resultOfUser= new UserContacts();
            console.info("From service: "+ resultOfUser);
            return resultOfUser;
        } catch (error) {
            let resultOfUser:UserContacts = new UserContacts();
            return resultOfUser;
        }
    }



    /**
     * UpdateUserInfo
     */
    public AddContactId(_contactId: string) {
        try {
            return this.store.dispatch(new AddContactIdAction(_contactId));
        } catch {
            return "";
        }
    }



    /**
     * GetContactId
     */
    public GetContactId(): string {
        let userContactId = this.store.selectSnapshot(ContactIdSelectors.getContactIdItems);
        return userContactId;
    }

}
