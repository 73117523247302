<div class="menu-icon" (click)="openMenu()">
  <i class="{{menu_icon}}"></i>
</div>
<div class="desktop-menu" [class.mobile_menu]="menuValue">
  <ul>
      <li *ngIf="isAuthorized" (click)="closeMenu()">
        <a href="/mypage" class="text-white">Min side</a>
      </li>
      <li *ngIf="isAuthorized"(click)="closeMenu()" >
        <a [routerLink]="['/inbox']" class="text-white">Innboks ({{inboxCount}})</a>
      </li>
      <li *ngIf="isAuthorized" (click)="closeMenu()">
        <a [routerLink]="['/membershipbenefits']" class="text-white">Medlemsfordeler</a>
      </li>
      <li *ngIf="isAuthorized"(click)="closeMenu()" >
        <a [routerLink]="['/course']" class="text-white">Kurs</a>
      </li>
      <li>
        <a class="border rounded-5 nav-link text-black bg-white logout-btn" role="button" *ngIf="isAuthorized" 
          (click)="inLogOut()" href="">Logg ut</a>

        <a class="border rounded-5 nav-link text-black bg-white logout-btn" role="button" *ngIf="!isAuthorized"
          href="/login">Logg inn</a>
      </li>
  </ul>
</div>
