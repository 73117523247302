import { Selector } from "@ngxs/store";
import { UserInfoState, UserInfoStateModel } from "./user-info.state";
import { UserContacts } from "@app/xModels/UserContacts";

export class UserInfoSelectors {    
   
    @Selector([UserInfoState])
    static getUserInfoItems(state: UserInfoStateModel) :UserContacts{
       
        return state.userInfodata;
    }
}