import { Injectable } from "@angular/core";
import { UserContacts } from "@app/xModels/UserContacts";
import { Action,  Selector, State, StateContext } from "@ngxs/store";

import { AddUserInfoAction, RemoveUserInfoAction } from "./user-info.actions";


export interface UserInfoStateModel {
    userInfodata: UserContacts;
}



@State<UserInfoStateModel>({
    name: 'userInfo',
    defaults: {
        userInfodata: new UserContacts(),
    },
})

@Injectable()
export class UserInfoState {

    @Action(AddUserInfoAction)    
    add(ctx: StateContext<UserInfoStateModel>,  action : AddUserInfoAction,) {
        
        const {payload} = action; 
       /*
 const {userInfodata  } = payload; 

         userName: UserContacts = {
            acan_land: payload.acan_land,
            accessToken:  payload.,
            firstname: userRoles,
            lastname: "",
            contactid: "",
            address1_city:"",
            mobilephone:"",
            telephone1:"",
            telephone2:"",
            address1_line1:"",
            address1_composite:"",
            address1_postalcode:"",
            emailaddress1:"",
            ctg_medlemi:"",
            address1_postofficebox:"",
            emailaddress2
          }


*/

        ctx.setState({userInfodata:  payload,});
        console.log(ctx.getState());
    }

    
    @Action(RemoveUserInfoAction)
    remove({patchState}: StateContext<UserInfoStateModel>): void {
        patchState({userInfodata: new UserContacts() });
    
   }

}