import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
//import { environment } from '@environments/environment';

@Injectable({providedIn: 'root'})


export class RootService {
  private httpOptions = {
    headers: new HttpHeaders().append('Content-Type', 'application/json; charset=utf-8')
    .append('Access-Control-Allow-Headers', 'Content-Type')
      //.append('Access-Control-Allow-Headers', 'Content-Type')
    //  .set('Access-Control-Allow-Origin',"http://localhost:4200/")
  };
  //let headers = new HttpHeaders().set('access-control-allow-origin',"http://localhost:8080/");
  constructor(private http: HttpClient) {}

  public get<T>(endpoint: string): Observable<T> {
    return this.http.get<T>(environment.apiUrl + '/' + endpoint);
  }

  public post<T>(endpoint: string, body: any): Observable<T> {
    return this.http.post<T>(environment.apiUrl + '/' + endpoint, body, this.httpOptions);
  }

  public delete<T>(endpoint: string): Observable<T> {
    return this.http.delete<T>(environment.apiUrl + '/' + endpoint, this.httpOptions);
  }

}
