import { Pipe, PipeTransform } from '@angular/core';
  

@Pipe({
  name: 'phoneFormat'
})

export class PhoneFormatPipe implements PipeTransform {  

  transform(number: string) {

    if (number.charAt(0) == "0"){
        number = number.substring(2) ;
        
    } 
    let newStr = "";
    let i = 0;  

    for (; i < Math.floor(number.length / 4) - 1; i++) {
      newStr = newStr + number.substr(i * 2, 2) + " ";
    } 

    return  "+" + newStr + number.substr(i * 2);

  }

} 