import { Injectable } from "@angular/core";
import { TokenService } from "./token.service";

@Injectable({
    providedIn: 'root'
})
export class HelperService {


    constructor(private tokenService: TokenService) { }

    public isClassEmpty(userClass: any): Boolean {
        let resultBool = Object.keys(userClass).length != 0;
        return resultBool;
    }


    public isEmptyOrSpaces(str: string | undefined) {
        if (str === undefined) {
            return true
        } else
            return str === null || str!.match(/^ *$/) !== null;
    }
}