import { Component, EventEmitter, OnInit, Input, Output, ViewContainerRef } from '@angular/core';
import swal from 'sweetalert2';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogResponseSendEmail, MemberOnGroup } from '@app/xModels/MemberGroupModel';

@Component({
  selector: 'app-dialog-sendemail',
  templateUrl: './dialog-sendemail.component.html',
  styleUrls: ['./dialog-sendemail.component.scss']
})


export class DialogSendEmailComponent implements OnInit {
    public modelRef: NgbModalRef | undefined;

    @Input() memberOnGroupObject: MemberOnGroup[] | undefined;
    @Input() appDisable: boolean =false;

    @Output()
    onSubmit = new EventEmitter();

    public emailSubject: string = '';
    public emailBody: string = '';
    public invalidSubjectFieldClass: string = '';
    public invalidBodyFieldClass: string = '';

    constructor(private modalService: NgbModal) {
        
    }

    ngOnInit(): void { 
        if (typeof this.memberOnGroupObject === 'undefined' || this.memberOnGroupObject == null) this.memberOnGroupObject = [];
        if (typeof this.appDisable === 'undefined' || this.appDisable == null) this.appDisable = false;
    }

    open(content: any) {
        this.invalidSubjectFieldClass = '';
        this.invalidBodyFieldClass = '';
        this.emailSubject = '';
        this.emailBody = '';
        
        this.modelRef = this.modalService.open(content, { size: 'lg' });
    }

    submit() {
        this.invalidSubjectFieldClass = '';
        this.invalidBodyFieldClass = '';

        if (this.emailBody == '') {
            this.invalidBodyFieldClass = 'invalidfield';
        } 
        if (this.emailSubject == '') {
            this.invalidSubjectFieldClass = 'invalidfield';
        }

        if (this.invalidBodyFieldClass != '' || this.invalidSubjectFieldClass != '') {
            return;
        }

        let res: DialogResponseSendEmail = {
            emailBody: this.emailBody.replace(/\n/g, '<br/>'),
            emailSubject: this.emailSubject,
            recipientIds: this.memberOnGroupObject?.filter(a => !a.isAccountMember)?.map(a => a.memberContactId) ?? [],
            accountRecipientIds: this.memberOnGroupObject?.filter(a => a.isAccountMember)?.map(a => a.memberContactId) ?? [],
        };
        this.onSubmit.emit(res);
        this.modelRef!.close();
    }

    dismiss() {
        this.modelRef!.close();
    }
}
