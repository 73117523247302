<div class="row">
  <div class="col-md-12 mt-2">
    <form [formGroup]="updateContactForm">  
      <div class="row ps-3 flex-row-reverse">
        <div class="col-md-3">
          <div class="title-tools" *ngIf="!editModeContactInfo">
            <div class="icon-flex">
              <div class="icon-wrapper btn-wrapper-info" (click)="editModeContact()">
                <i class="bi bi-pencil-fill"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 title-container">
          <p class="fs-4 ">{{userContactInfo.firstname}}&NonBreakingSpace;{{userContactInfo.lastname}}</p>
          
          <ng-container *ngIf="!editModeContactInfo">
            <div class="row">
              <div class="col-12">
                <p *ngIf="(userContactInfo.address1_line1!==undefined)">
                  {{userContactInfo.address1_line1}},&nbsp; {{userContactInfo.address1_postalcode}}, &puncsp;
                  {{userContactInfo.address1_postofficebox}} {{userContactInfo.address1_city}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p *ngIf="userContactInfo.emailaddress2!==undefined"> {{userContactInfo.emailaddress2}} </p>     
                <p ><strong><i class="bi bi-envelope-fill me-2 bi--lg  ">        
                </i></strong>E-post:{{userContactInfo.emailaddress1}}</p>
              </div>
              <div class="col-md-6">
                <p ><strong><i class="bi bi-telephone-fill me-2 bi--lg"></i> </strong>Mobil : {{userContactInfo.mobilephone |
                  phoneFormat}}</p>
              </div>
            </div>
            <div class="row" *ngIf="userContactInfo.sendInvoiceVia">
              <div class="col-md-6">
                <p>Send faktura via:&nbsp;{{ displayInvoiceLabel(userContactInfo.sendInvoiceVia)  }}</p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    
      <!-- EDIT MOED-->
      <ng-container *ngIf="editModeContactInfo">
        <div class="row  mt-3">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label for="nInput7" class="form-label small">Vei</label>
            <input type="text" 
              class="form-control" 
              formControlName="locationOrStreetName" 
              id="nInput7" 
              placeholder="" 
              maxlength="200"
              [ngClass]="{ 'is-invalid ':updateContactForm.get('locationOrStreetName')!.touched && updateContactForm.get('locationOrStreetName')!.invalid,  'is-valid ':updateContactForm.get('locationOrStreetName')!.touched && updateContactForm.get('locationOrStreetName')!.valid}">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label for="nInput5" class="form-label small">Postnummer</label>
            <input type="text" 
              class="form-control" 
              formControlName="zipCode" 
              id="nInput5" 
              placeholder="" 
              maxlength="8"
              [ngClass]="{ 'is-invalid ':updateContactForm.get('zipCode')!.touched && updateContactForm.get('zipCode')!.invalid,  'is-valid ':updateContactForm.get('zipCode')!.touched && updateContactForm.get('zipCode')!.valid}">
          </div>
        </div>
        <div class="row  mt-2">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label for="nInput6" class="form-label small">Poststed</label>
            <input type="text" 
              class="form-control" 
              formControlName="city" 
              id="nInput6" 
              placeholder="" 
              maxlength="100"
              [ngClass]="{ 'is-invalid ':updateContactForm.get('city')!.touched && updateContactForm.get('city')!.invalid,  'is-valid ':updateContactForm.get('city')!.touched && updateContactForm.get('city')!.valid}">
          </div>
        </div>
        <!--email-->
        <div class="row mt-3">
          <div class="col-lg-6  col-md-6 col-sm-12">
            <label for="nInput3" class="form-label small">E-post</label>
            <input type="email" 
              class="form-control" 
              formControlName="userEmail" 
              id="nInput3" 
              placeholder="" 
              maxlength="200"
              [ngClass]="{ 'is-invalid ':updateContactForm.get('userEmail')!.touched && updateContactForm.get('userEmail')!.invalid,  'is-valid ':updateContactForm.get('userEmail')!.touched && updateContactForm.get('userEmail')!.valid}">

            <!--div class="input-group ">
              <i class="bi bi-envelope-fill input-group-text"></i>
              <input [value]="userContactInfo.emailaddress1" type="email" class="form-control" formControlName="userEmail"
                id="nInput3" placeholder=""
                [ngClass]="{ 'is-invalid ':updateContactForm.get('userEmail')!.touched && updateContactForm.get('userEmail')!.invalid,  'is-valid ':updateContactForm.get('userEmail')!.touched && updateContactForm.get('userEmail')!.valid}">

            </!--div-->
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label for="nMobilePhone" class="form-label small">Mobil</label>
            <input type="text" 
              class="form-control" 
              id="nMobilePhone" 
              maxlength="200" readonly="readonly" [value]="userContactInfo.mobilephone" [disabled]="true" />
            <!--p class=" fw-semibold"><i class="bi bi-telephone-fill me-2 bi--lg"></i> Mobil : {{userContactInfo.mobilephone |
              phoneFormat}}</!--p-->
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-6  col-md-6 col-sm-12">
            <label for="nInput3" class="form-label small">Send faktura via</label>
            <select class="form-select" formControlName="sendInvoiceVia" id="nInput3">
              <option *ngFor="let opt of invoiceOption" [value]="opt.value">{{opt.label}}</option>
            </select>
          </div>
        </div>
        <div class="row  mt-3">
          <div class="col-12 text-end" [ngClass]="(editModeContactInfo) ? 'mb-2' : ''">
            <button class=" btn btn-primary text-nowrap f-2 fw-bold btn-wrapper-info" (click)="onSaveDataContactInfo()"
              [disabled]="saveDataButton">Lagre</button>
            <button class=" btn text-nowrap  f-2 fw-bold " (click)="editModeContactInfo=false">Avbryt</button>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>
