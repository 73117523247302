import { Injectable } from '@angular/core';
import { UserStatusModel } from '@app/xModels/UserStatusModel';
import { RootService } from './root.service';
import { BaseModel } from '@app/xModels/BaseModel';

@Injectable({
  providedIn: 'root'
})
export class BankidService {


  constructor(private httRoot: RootService) { }

  /**
   * Check Login Status by operationId
operationId:st   */
  public checkLoginStatus(operationId:string) {
    let _url="bankid/status/"+operationId;
    return this.httRoot.get<UserStatusModel>(_url);
  }

  public GetBankIdInfo(code: string) {
    let _url="auth/bankid/"+code;    
    return this.httRoot.get<BaseModel<UserStatusModel>>(_url);
  }
}
