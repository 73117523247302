<div class="container-fluid">
    <main class="container container-main ">
      <div class=" mb-3 row ">
        <div class="col-12 mt-5 mb-5 text-center">
          <h3>Bekreft dine opplysninger</h3>
        </div>
      </div>
  
        <ngb-alert type="danger" *ngIf="statusType" [dismissible]="false" class="pt-5 text-center">
          <span class="mt-5 mb-5 text-center"><strong >Linkdatoen din er utløpt.</strong><br/><br/><p>Kontakt administratoren din. Eller følg linken for å registrere deg.</p>
          </span>
          <div class="mt-5 pb-5 text-center">
            <button type="button" class="btn btn-primary pt-2 pe-3 pb-2 ps-3 "  (click)="Redirect()">Bli medlem</button>
          </div>
        </ngb-alert>
  
      <div class="row justify-content-center" *ngIf="!statusType">
        <form [formGroup]="updateUserForm" class="form-container">
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12  ">
              <label for="nInput1" class="form-label small">Fornavn</label><span class="text-danger star"> *</span>
              <input
              type="text"
              class="form-control"
              formControlName="firstName"
              id="nInput1"   [ngClass]="{ 'is-invalid ':updateUserForm.get('firstName')!.touched && updateUserForm.get('firstName')!.invalid,  'is-valid ':updateUserForm.get('firstName')!.touched && updateUserForm.get('firstName')!.valid}">
  
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
              <label for="nInput2" class="form-label small">Etternavn</label><span class="text-danger star"> *</span>
              <input type="text" class="form-control" formControlName="lastName" id="nInput2" placeholder=""
               [ngClass]="{ 'is-invalid ':updateUserForm.get('lastName')!.touched && updateUserForm.get('lastName')!.invalid,  'is-valid ':updateUserForm.get('lastName')!.touched && updateUserForm.get('lastName')!.valid}">
  
            </div>
          </div>
  
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12  ">
              <label for="nInput3" class="form-label small">E-post</label><span class="text-danger star"> *</span>
              <input type="email" class="form-control" formControlName="userEmail" id="nInput3" placeholder="" [ngClass]="{ 'is-invalid ':updateUserForm.get('userEmail')!.touched && updateUserForm.get('userEmail')!.invalid,  'is-valid ':updateUserForm.get('userEmail')!.touched && updateUserForm.get('userEmail')!.valid}">
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
              <label for="nInput4" class="form-label small">Mobil</label><span class="text-danger star"> *</span>
              <div class="input-group flex-nowrap">
              <span class="input-group-text" id="addon-wrapping">+47</span><input type="tel" class="form-control" formControlName="userPhone" id="nInput4" placeholder=""
              [ngClass]="{ 'is-invalid ':updateUserForm.get('userPhone')!.touched && updateUserForm.get('userPhone')!.invalid,  'is-valid ':updateUserForm.get('userPhone')!.touched && updateUserForm.get('userPhone')!.valid}">
            </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12  ">
              <label for="nInput5" class="form-label small">Postnr.</label><span class="text-danger star"> *</span>
              <input type="text" class="form-control" formControlName="zipCode" id="nInput5" placeholder=""
                maxlength="8"
                [ngClass]="{ 'is-invalid ':updateUserForm.get('zipCode')!.touched && updateUserForm.get('zipCode')!.invalid,  'is-valid ':updateUserForm.get('zipCode')!.touched && updateUserForm.get('zipCode')!.valid}">
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
              <label for="nInput6" class="form-label small">Poststed</label>
              <input type="city" class="form-control" formControlName="city" id="nInput6" placeholder="">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12  ">
              <label for="nInput7" class="form-label small">Adresse</label><span class="text-danger star"> *</span>
              <input type="address" class="form-control" formControlName="locationOrStreetName" id="nInput7"
                placeholder=""
                [ngClass]="{ 'is-invalid ':updateUserForm.get('locationOrStreetName')!.touched && updateUserForm.get('locationOrStreetName')!.invalid,  'is-valid ':updateUserForm.get('locationOrStreetName')!.touched && updateUserForm.get('locationOrStreetName')!.valid}">
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 ">
              <label for="nInput8" class="form-label small">Land</label><span class="text-danger star"> *</span>
              <select class="form-select" formControlName="country" id="nInput8" required
                [ngClass]="{ 'is-invalid ':updateUserForm.get('country')!.touched && updateUserForm.get('country')!.invalid,  'is-valid ':updateUserForm.get('country')!.touched && updateUserForm.get('country')!.valid}">
                <option *ngFor="let country of countryPicklist" [value]="country.value">
                  {{country.label}}
                </option>
              </select>
  
            </div>
          </div>
  
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12  ">
              <label for="nInput5" class="form-label small">Fødsels/D-number</label><span class="text-danger star"> *</span>
              <input type="number" class="form-control" formControlName="DNumber" id="nInput9" placeholder=""
                maxlength="16"
                [ngClass]="{ 'is-invalid ':updateUserForm.get('DNumber')!.touched && updateUserForm.get('DNumber')!.invalid,  'is-valid ':updateUserForm.get('DNumber')!.touched && updateUserForm.get('DNumber')!.valid}">
  
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr class="mt-4 mb-4">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12  ">
              <label for="nInput5" class="form-label small">Yrke/Bransje</label>
              <select class="form-select" formControlName="brunch" id="nInput8" >
                <option *ngFor="let brunch of businessSectorPicklist" [value]="brunch.value">
                  {{brunch.label}}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12  ">
              <label for="nInput5" class="form-label small">Vervet av</label>
              <input type="text" class="form-control" formControlName="enlistedBy" id="nInput9" placeholder=""
                maxlength="35">
                <hr class="mt-4 mb-4">
            </div>
          </div>
  
  
          <div class="row">
            <div class="col-md-12 col-sm-12  ">
              <div class="form-check mb-2">
                <input class="form-check-input " type="radio" value="true"  name="newsletter" id="news" (change)="changeSubscribe($event)"  [checked]="newsSubscription">
                <label class="form-check-label" for="flexRadioDefault1">
                  <strong>Ja</strong>, jeg ønsker nyhetsbrev
                </label>
              </div>
              <div class="form-check ">
                <input class="form-check-input" type="radio" value="false" name="newsletter" id="nonews" (change)="changeSubscribe($event)"  [checked]="!newsSubscription">
                <label class="form-check-label" for="flexRadioDefault2">
                  <strong> Nei</strong>, jeg ønsker ikke nyhetsbrev
                </label>
                <hr class="mt-4 mb-4 ">
              </div>
  
            </div>
          </div>
  
  
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-check mb-4">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"   formControlName="privacy" >
                <label class="form-check-label mb-2" for="flexCheckDefault">
                  Samtykke personvern
                </label>
                <br>
                <a class="text-decoration-none" href="https://krifa.no/behandling-av-personopplysninger/" target="_blank" rel="noopener noreferrer"> Her kan du lese mer om hvordan vi behandler dine <strong>personopplysninger.</strong></a>
              </div>
             
            </div>
          </div>
  
          <div class="row mt-5">
            <div class="d-grid gap-2 col-3 mx-auto mb-5">
              <button class="btn btn-primary  text-nowrap" type="submit"  id="btnSubmit"  [disabled]="!updateUserForm.valid || disableSubmitBtn" (click)="onSubmit()">Gå videre</button>
            </div>
          </div>
        </form>
      </div>
    </main>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
      <p style="color: white"> Updating... </p>
    </ngx-spinner>
  </div>
 
  
  
  