<p>Agreement!</p>
<div class="copy article__body">
    <p>In these Terms of Use, any use of the words "you", "yours" or similar expressions shall mean any user of this website and the app whatsoever. Terms such as "we", "us, "our" or similar expressions shall mean the Football Association Premier League Limited.</p>
<p>This website, <a href="#">www.premierleague.com</a> (the “Website”), and the Premier League mobile application (the “App”) are operated by the Football Association Premier League Limited, a company registered in England and Wales under company number 02719699 with registered office at The Premier League, Brunel Building, 57 North Wharf Road, London, W2 1HQ. Our VAT number is 672 6255 22.</p>
<p>Please read this page carefully as it sets out the terms that apply to your use of the Website and the App, and any part of their content and all materials appearing on them. By using the Website you confirm that you accept these Terms of Use and you agree to comply with them. If you do not agree to these Terms of Use, please refrain from using the Website and App.</p>
<p><strong>YOUR USE OF THE WEBSITE IF YOU ARE UNDER 18</strong></p>
<p>If you are under 18, you may need your parent/guardian to help you with your use of the Website and the App and with reading these Terms and Conditions. If anything is hard to understand, please ask you parent/guardian to explain. If you still have any questions, you or your parent/guardian can contact us at: [<a href="#">info@premierleague.com</a>].</p>
<p>If you are aged 13 or under, you cannot register for a Premier League account (“Account”) without the permission of your parent/guardian. You need to register if you want to play the Fantasy game. You do not need to register to use the Website or App.</p>
<p>We may collect your personal information when you use the Website and the App. You can find out more about how we use your personal information in our Questions and Answers. You can find a link here: <a href="#">link</a>.  </p>
<p><strong>OTHER APPLICABLE TERMS</strong></p>
<p>These Terms of Use are additional to, and should be read in conjunction with, our <a title="Link to Privacy Policy page" href="#">Privacy Policy</a> and <a title="Link to Cookie Policy page" href="#">Cookie Policy</a>.</p>
<p>If you are under 18, we have tried to explain these policies for you in our Questions and Answers. You can find a link here: <a href="#">link</a>. </p>
<p><strong>CHANGES TO THESE TERMS OF USE</strong></p>
<p>We may change these terms at any time by amending this page. Please check this page regularly to take notice of any such changes as you will be deemed to accept them through your continued use of the Website and the App.</p>
<p><strong>CHANGES TO THE WEBSITE</strong></p>
<p>We aim to update the Website regularly, and may change the content at any time. If the need arises, we may suspend access to the Website, or close it indefinitely. We will not be liable if for any reason the Website is unavailable at any time or for any period. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.</p>
<p><strong>INTELLECTUAL PROPERTY RIGHTS</strong></p>
<p>The Website is protected by copyright, database rights and other intellectual property and related rights ("Rights") which are owned by us and our suppliers. All such Rights are reserved. Except where otherwise indicated on the Website:</p>
<ul>
<li>You may download and print material from the Website as is reasonable for your own private and personal use;</li>
<li>You may also forward such material from the Website to other people for their private and personal use provided you credit us as its source and add the Website address: <a href="#">www.premierleague.com</a>. You must draw their attention to these terms which also apply to them; and</li>
<li>You may provide links to the Website provided they go to the home page only and provided you do so in a way that is fair, legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on the part of us where none exists.</li>
</ul>
<p>The Website must not be used in any other way, including for commercial purposes, and you may not otherwise reproduce, re-utilise or redistribute it (including, by way of example, creating a database (electronic or otherwise) that includes material downloaded or otherwise obtained from the Website), or frame or deep-link to it (or to any of its content) on any other website, without our prior written approval. If you print off, copy or download any part of the Website in breach of these terms, your right to use the Website will cease immediately and you must return or destroy any copies of the materials you have made at our request.</p>
<p>The trade marks, logos and brand names shown on the Website are owned by us or our suppliers, partners or member Clubs. No rights are granted to use any of them without the prior written permission of the owner.</p>
<p><strong>ACCOUNT</strong></p>
<p>You may register for an Account. You are not permitted to register multiple Accounts on the Website or App.</p>
<p>In registering for an Account, you will be providing personal data to the Premier League. Any personal data which you do submit will be processed in accordance with the Premier League’s Privacy Policy and in accordance with relevant data protection legislation including the General Data Protection Regulation (“GDPR”) and the Data Protection Act 2018. If you are under 18, you can find out more about how we use your personal information in our Questions and Answers, which is available here: <a href="#" target="_blank" rel="noopener">link</a>. We will only share personal data in accordance with our Privacy Policy or if required to do so by a competent authority or court within the United Kingdom.</p>
<p>You may (at your sole discretion) enable two factor authentication on your Account. Two factor authentication improves the security of users’ accounts. Before enabling two factor authentication, we recommend that you read our guide on two factor authentication which can be found here: <a href="#" target="_blank" rel="noopener">link</a>.</p>
<p>You are responsible for:</p>
<ul>
<li>Ensuring that your equipment is enabled with appropriate up-to-date virus checking software;</li>
<li>Maintaining the security of your Account and/or password including (without limitation) by keeping passwords for your Account secure, frequently changing your password and keeping it confidential; and</li>
<li>Implementing two factor authentication on your Account.</li>
</ul>
<p><strong>NO RELIANCE ON INFORMATION</strong></p>
<p>Reasonable skill and care has been used in producing the Website but it is only designed for general information purposes. No guarantee is given by us or our suppliers that the Website (including any statistics contained on the Website) is accurate, complete or up-to-date. We therefore disclaim all liability and responsibility arising from any reliance placed on the content of the Website by you, or by anyone who may be informed of the Website’s contents.</p>
<p><strong>EXTERNAL LINKS</strong></p>
<p>The Website contains links to other websites and services operated by parties independent to the us. We take reasonable precautions in selecting these however we do not endorse or take responsibility for the content or availability of these websites and cannot accept any liability for any material contained within them or for your use of them or any use of your personal data collected by their operators. If you decide to access linked websites you do so at your own risk and we encourage you to read the privacy statements applicable to each specific website.</p>
<p>If you are under 18, you may need your parent/guardian to help you with your use of the Website and the App and with ensuring the content and availability of any linked websites and services are appropriate.</p>
<p><strong>VIRUSES</strong></p>
<p>We and our suppliers take reasonable precautions to prevent computer viruses, trojan horses, worms, time bombs, cancelbots, corrupted files, or any other items that may damage the operation of computers or property or otherwise engage in computer misuse ("Malicious Programmes") on the Website but cannot accept any liability for them. You are advised to take precautions against such Malicious Programmes, including the use of suitable protective software.</p>
<p><strong>YOUR LAWFUL USE OF THE WEBSITE</strong></p>
<p>You must not misuse the Website by knowingly introducing Malicious Programmes. You must not attempt to gain unauthorised access to the Website, the server on which the Website is stored or any server, computer or database connected to the Website. You must not attack the Website via a denial-of-service attack or a distributed denial-of-service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use the Website will cease immediately.</p>
<p><strong>LIMITATION OF LIABILITY</strong></p>
<p>Except in the case of death or personal injury caused by our negligence or any wilful act, to the fullest extent permitted by law we exclude all liability to you in respect of your Account and your use of the Website and App.</p>
<p><strong>GOVERNING LAW</strong></p>
<p>These Terms of Use are governed by and interpreted in accordance with the laws of England and Wales. Any disputes arising under or in connection with these terms shall be subject to the non-exclusive jurisdiction of the English courts.</p>
<p><strong>CONTACT</strong></p>
<p>If you have any comments or questions about the Website or the App please contact us at <a href="#">info@cccc.com</a>.</p>
</div>