
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CrmService } from './crm.service';

@Injectable({
  providedIn: 'root'
})
export class EducationService {

  constructor(private crmService: CrmService, private domSanitizer: DomSanitizer) { }

  public TerminateEducation(educationId: string) {
    var endPoint = "education/terminate/" + educationId;
    return this.crmService.patch<any>(endPoint, {});
  }

  public upsertEducation(contactId: string, educationId: string, name: string, startDate: string, endDate: string) {
    var record: any = {};
    record.ctg_contactId = contactId;
    record.ctg_name = name;
    record.ctg_datefrom = startDate;
    if (educationId != "") {
      record.ctg_educationId = educationId;
    }
    if (endDate) {
      record.ctg_dateto = endDate;
    }

    var endPoint = "education/upsert";
    let sendData = JSON.stringify(record);
    return this.crmService.post<any>(endPoint, sendData, false);
  }

};

