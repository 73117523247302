
import { Component, OnInit } from '@angular/core';
import { StoreManageService } from '@app/xServices/StoreManageService.service';


@Component({
  selector: 'ctg-user-my-page',
  templateUrl: './user-my-page.component.html',
  styleUrls: ['./user-my-page.component.scss']
})
export class UserMyPageComponent implements OnInit {
  
  public userId: string = "";

  constructor(private storeManageService:StoreManageService) {
    this.userId = this.storeManageService.GetContactId();
  }

  ngOnInit(): void {
    
  }
}
