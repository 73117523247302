<div class="container-fluid">
  <main class="container container-main ">
    <div class="row ">
      <div class="col-12 mt-5 mb-5 text-center">
        <h3>Fakturaoversikt </h3>
      </div>
    </div>
    <div class="row  infoWindow">
      <div class="col-lg-12 pt-5 pb-5 text-center ">
        <h5 class="fw-bolder">ingen ubetalte fakturaer</h5>
      </div>
    </div>



      <div class="row ">
        <div class="col-lg-4 mt-5 ">
          <label>Ønsket faktureringsintervall: </label>
        </div>
        <div class="col-lg-8 mt-5 ">
          <div ngbDropdown class="d-inline-block ">
            <button type="button" class="btn btn-light selectClass fs-5" id="dropdownBasic1" ngbDropdownToggle >{{selectedPeriodicity.name}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem *ngFor="let periodicityItem of periodicity" (click)="onPeriodicityChanged(periodicityItem)"> {{periodicityItem.name}}</button>
            </div>
          </div>
        </div>
      </div>



      <div class="row ">
        <div class="col-4 mt-5  ">
          <label>Ønsket faktureringsintervall: </label>
        </div>

          <div class="col-lg-8 mt-5 ">
            <div ngbDropdown class="d-inline-block ">
              <button type="button" class="btn btn-light selectClass fs-5" id="dropdownBasic1" ngbDropdownToggle >{{selectedPeriodicity.name}}</button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem *ngFor="let periodicityItem of periodicity" (click)="onPeriodicityChanged(periodicityItem)"> {{periodicityItem.name}}</button>
              </div>

          </div>
        </div>
      </div>


    <div class="row ">
      <div class="col-12 mt-5  ">
        <label>Nullam tincidunt magna in augue dapibus, quis tincidunt nibh efficitur. Suspendisse mattis dictum
          pulvinar. Curabitur quis pretium velit. Nulla vitae fringilla purus. </label>
      </div>
    </div>
    <div class="row ">
      <div class="col-12 mt-5  ">
        <ngb-accordion (panelChange)="beforeChange($event)">
          <ngb-panel id="preventchange-1">
            <ng-template ngbPanelTitle>
              <span class="spinner-border spinner-border-sm me-3" *ngIf="loaded" role="status"
                aria-hidden="true"></span>
              <span>Fakturahistorikk - medlemskap i </span>
            </ng-template>
            <ng-template ngbPanelContent  >
              <div class="table-responsive">
              <table class="table table-sm table-hover  ">
                <thead>
                  <tr>
                    <th scope="col">Number</th>
                    <th scope="col">Dato</th>
                    <th scope="col">Mottaker</th>
                    <th scope="col">Beløp</th>
                    <th scope="col ">Status</th>
                    <th scope="col">Forfallsdato</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let invoice of invoicesList">
                    <td >{{ invoice.id }}</td>
                    <td>
                      {{invoice.date | date :'dd/MM/yyyy'}}
                    </td>
                    <td>
                      {{ invoice.name }}
                    </td>
                    <td>{{ invoice.amount| number:'2.2-2'}}</td>
                    <td ><span   [ngClass]="{'isSstatus': invoice.status==='Belalt'}"  >   {{ invoice.status }}</span></td>
                    <td>{{ invoice.dueDate| date :'dd/MM/yyyy'}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </main>
</div>
