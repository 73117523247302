import { IInvoiceList, InvoiceList } from './../../xModels/InvoiceList';
import { Component, OnInit } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ctg-invoice-main',
  templateUrl: './invoice-main.component.html',
  styleUrls: ['./invoice-main.component.scss']
})
export class InvoiceMainComponent implements OnInit {
  private invoices!: IInvoiceList;
  public invoicesList: IInvoiceList[] = [];
  public loaded: boolean = false;

  //TODO edit to normal model
  public periodicity!: { id: number; name: string; }[];
public selectedPeriodicity!: { id: number; name: string; };

  constructor() {
    this.periodicityFill();
  }

  ngOnInit(): void {

  }

  public beforeChange($event: NgbPanelChangeEvent) {
    if ($event.panelId === 'preventchange-1') {
      this.loaded= true;
      setInterval(() => { this.temporaryFillInvoiceList(); this.loaded = false; }, 1000);

      // $event.preventDefault();
    }
  }

  public onPeriodicityChanged(newPeriodicity: any) {

      this.selectedPeriodicity = newPeriodicity;
      console.info("Selected plan: "+this.selectedPeriodicity.name);
  }


  private temporaryFillInvoiceList() {
    this.invoicesList = [
      {
        id: 123457,
        name: 'Navn Navnesen',
        amount: 239.54,
        status: "Ubelalt",
        date: new Date().toDateString(),
        dueDate: (new Date().getDate() + 18).toString()
      },
      {
        id: 223457,
        name: 'Navn Navnesen',
        amount: 73905.87,
        status: "Belalt",
        date: (new Date().getDate() - 12).toString(),
        dueDate: (new Date().getDate() - 50).toString()
      },
      {
        id: 323457,
        name: 'Navn Navnesen',
        amount: 5040.02,
        status: "Belalt",
        date: (new Date().getDate() - 55).toString(),
        dueDate: (new Date().getDate() - 65).toString()
      },
      {
        id: 423457,
        name: 'Navn Navnesen',
        amount: 700484.00,
        status: "Belalt",
        date: (new Date().getDate() - 75).toString(),
        dueDate: (new Date().getDate() - 79).toString()
      }
    ];
  }

private periodicityFill(){
   this.periodicity= [
    {
      id: 1,
      name: 'Annual'
    },
    {
      id: 2,
      name: 'Monthly'
    },
    {
      id: 3,
      name: 'Weekly'
    },
    {
      id: 4,
      name: 'Daily'
    },
    {
      id: 5,
      name: 'Hourly'
    }
  ];

  this.selectedPeriodicity= this.periodicity[0];
}



}
