
<div class="container-bg-color">
  <div class="row justify-content-center ">
    <div class=" col-lg-12 col-md-12 mt-5 mb-5 pb-5 text-center">
    <main class="login">  
      <h4 class="login__title">VELG INNLOGGINGSMETODE</h4>
      <ngb-alert type="danger" *ngIf="displayStatus" [dismissible]="false" class="message__form mb-3 text-center">
        <label [innerHTML]="displayMessageStatus"></label>
      </ngb-alert>

      <div class="login__form">
        <form [formGroup]="loginForm" class="login-form">
          <div class="login__inputs">
            <div class="login__box">
              <input type="text"  class="form-control fs-5" formControlName="username"  id="txtUsername" maxlength="50" placeholder="E-postadresse"
              [ngClass]="{ 'is-invalid ':loginForm.get('username')!.touched && loginForm.get('username')!.invalid,  'is-valid ':loginForm.get('username')!.touched && loginForm.get('username')!.valid}">
  
            </div>

            <div class="login__box">
              <input type="password"  class="form-control fs-5" formControlName="password"  id="txtPassword" maxlength="50" placeholder="Passord"
              [ngClass]="{ 'is-invalid ':loginForm.get('password')!.touched && loginForm.get('password')!.invalid,  'is-valid ':loginForm.get('password')!.touched && loginForm.get('password')!.valid}">
          
            </div>
          </div>

          <div class="login__check">
            <a [routerLink]="['/resetpassword', 'email']" class="login__forgot">Glemt passord?</a>
          </div>

          <div class="d-grid">
            <button class="btn btn-lg btn-primary" id="btnSubmit" type="submit"
                (click)="onSubmit()">Logg inn</button>
          </div>

          <div class="login__register" >
              Har du ikke en konto?  <a [routerLink]="['/registration']" [queryParams]="{ mode: 'member' }">Registrer</a>
          </div>
        </form>  
      </div>
      
      <div class="separate-container">
        <div class="line-separate"></div>
      </div>
      
      <!--div class="login__form vipps-content" [routerLink]="['/vipplogin', 'noid']">
        <span class="vipps-login-btn">&nbsp;</span>
      </!--div-->

      <div class="login__form bankid-content" [routerLink]="['bankid', 'no-bankid']">
        <img src="./././assets/img/bankId.png" width="120" alt="">
      </div>

      <h5 class="mt-5 mb-5 "><strong> BankID </strong> er en elektronisk legitimasjon for sikker identifisering og signering på nettet</h5>
    </main>
  </div>
</div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Laster... </p>
</ngx-spinner>

