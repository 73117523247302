import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import { CourseInfo, CourseRegister } from '@app/xModels/CourseModel';
import { IPicklistCategories } from '@app/xModels/CrmModel';
import { IFileGetApiModel, FileGetApiModel } from '@app/xModels/FileGetApiModel';
import { IKategoriMedlemsFoldId, IMembershipBenefit, IMembershipBenefitCategory } from '@app/xModels/UserMembershipBenefits';
import { IUserNews } from '@app/xModels/UserNews';
import { CourseService } from '@app/xServices/course.service';
import { CrmService } from '@app/xServices/crm.service';
import { DecryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseModel, OptionSetItem } from '@app/xModels/BaseModel';
import { StoreManageService } from '@app/xServices/StoreManageService.service';
import swal from 'sweetalert2';
import { UserService } from '@app/xServices/user.service';
import { UserContacts } from '@app/xModels/UserContacts';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ParticipantService } from '@app/xServices/participant.service';
import { ParticipantInfo } from '@app/xModels/ParticipantModel';

@Component({
  selector: 'app-user-courseresult',
  templateUrl: './user-courseresult.component.html',
  styleUrls: ['./user-courseresult.component.scss']
})


export class UserCourseResultComponent implements OnInit {
  private sub: any;
  participantId: string = '';
  courseId: string = '';
  data?: CourseInfo;
  dataParticipant?: ParticipantInfo;
  mode: string = "read";
  public userInfo?: UserContacts;
  public registerCourseForm!: UntypedFormGroup;

  public invoiceOption: OptionSetItem[] = [
    { label:'E-post', value: 961700000},
    { label:'eFaktura ', value: 961700002},
    { label:'Vipps MobilePay', value: 961700004},
  ];
  
  constructor(private service: CourseService, 
    private participantService: ParticipantService,
    private sanitizer: DomSanitizer, 
    private spinner: NgxSpinnerService,
    private router : Router,
    private route: ActivatedRoute, 
    private datepipe: DatePipe,
    private storeManageService:StoreManageService,
    private userService: UserService,
    public fb: UntypedFormBuilder ) {
      this.spinner.show();
      this.sub = this.route.params.subscribe(params => {
        this.courseId = params['courseid']; 
        this.participantId = params['participantid']; 
      });
      this.GetInitialData();
  }

  ngOnInit() { 
    
  }

  private GetInitialData() {
    this.service.getCourse(this.courseId).subscribe({
      next: (data: BaseModel<CourseInfo>) => {
        if (!!data) {
          this.data = data.responseData;
        } 
      },
      complete: () => {
        this.spinner.hide();
      }
    });

    this.participantService.getParticipantInfo(this.participantId).subscribe({
      next: (data: BaseModel<ParticipantInfo>) => {
        if (!!data) {
          this.dataParticipant = data.responseData;
        } 
      },
      complete: () => {
        this.spinner.hide();
      }
    });

    this.userService.GetUserInfo(this.storeManageService.GetContactId()).subscribe(res =>
      {
        this.userInfo = res.responseData;
      });
  }

  displayDateRange(counse?: CourseInfo): string {
    if (!counse) {
      return '';
    }

    let fdate = counse.dateFrom ? new Date(counse.dateFrom).toLocaleDateString() : '';
    let edate = counse.dateTo ? new Date(counse.dateTo).toLocaleDateString() : '';
    if (fdate == edate) {
      return this.datepipe.transform(counse.dateFrom, 'dd.MM.yyyy HH:mm')! + ' - ' + this.datepipe.transform(counse.dateTo, 'HH:mm');
    }
    else {
      return `${this.datepipe.transform(counse.dateFrom, 'dd.MM.yyyy HH:mm')} - ${this.datepipe.transform(counse.dateTo, 'dd.MM.yyyy HH:mm')}`;
    }
  }
  
  displayTime(d: Date): string {
    return d.getHours().toString() + ':' + d.getMinutes().toString();
  }

}
