import { Component } from '@angular/core';
import { UserService } from '@app/xServices/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FormControl, UntypedFormBuilder,  UntypedFormGroup, Validators } from '@angular/forms';

import { WorkingInfo, Workplace } from '@app/xModels/WorkingInfo';
import { IPicklistCategories } from '@app/xModels/CrmModel';
import { CrmService } from '@app/xServices/crm.service';
import { TokenService } from '@app/xServices/token.service';
import { BaseModel } from '@app/xModels/BaseModel';

import {Store } from '@ngxs/store';


import { StoreManageService } from '@app/xServices/StoreManageService.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { WorkplaceService } from '@app/xServices/workplace.service';

@Component({
  selector: 'app-user-workplace',
  templateUrl: './user-workplace.component.html',
  styleUrls: ['./user-workplace.component.scss']
})
export class UserWorkplaceComponent {

  public userId: string = "";
  public createNewWorkplaceForm!: UntypedFormGroup;
  workplaceTypePicklist !: IPicklistCategories[];
  workType:string="";
  listWorkplaces: Workplace[] = [];

  editModeWorkplace: boolean = false;
  isDisplayEditDialog: boolean = false;
  btnSaveWorkplaceDisable: boolean = false;
  isValidSubmit: boolean = true;
  
  constructor(private userService: UserService,  
    private crmService: CrmService, 
    private spinner: NgxSpinnerService, 
    private router: Router,
    private tokenService: TokenService, 
    public fb: UntypedFormBuilder,
    private store:Store,
    private storeManageService:StoreManageService,
    private workplaceService: WorkplaceService,
  ) {
    this.spinner.show();
    this.userId = this.storeManageService.GetContactId();
    this.WorkingInitialization();
  }

  ngOnInit(): void {    
    this.createNewWorkplaceForm = this.fb.group({
      employer: [''],
      workplaceStartDate: [''],
      workplaceEndDate: [''],
      workplaceId: [''],
    });
  }

  /** EMPLOYER WORKPLACE  */
  private WorkingInitialization() {
    const futureDate = new Date(8640000000000000);

    if (this.userId == null || this.userId == undefined) {
      this.router.navigate(['/registration']);
    } else {
      try {
        this.userService.GetAllWorkplaces(this.userId).subscribe({
          next: (data: BaseModel<Workplace[]> ) => {
            if (data) {
              this.listWorkplaces = data.responseData.sort((a, b) => {
                let dateA = a.ctg_datefrom ? new Date(a.ctg_datefrom) : futureDate;
                let dateB = b.ctg_datefrom ? new Date(b.ctg_datefrom) : futureDate;
                return dateB.getTime() - dateA.getTime();
              });
            }            
          },
          error: (err) => {
            console.error("errorMessage: " + err);
            this.spinner.hide();
          },
          complete:()=>{
            //this.workType=this.getLabelFromWorkplacePicklist(this.currentWorkplace.ctg_type);
          },
        });
      } catch (error) {
        console.error("Could not retrieve workplace information");
      }
    }
  }

  newWorkplace() {
    this.isDisplayEditDialog = true;

    this.createNewWorkplaceForm.setValue({
      employer: "",
      workplaceStartDate: { day:0, month: 0, year: 0},
      workplaceEndDate: { day:0, month: 0, year: 0},
      workplaceId: "",
    }); 
  }

  editWorkplace(workplaceId: string | undefined) {
    if (!workplaceId)
      return;

    this.spinner.show();
    this.isDisplayEditDialog = true;
    let idx = this.listWorkplaces.findIndex(x => x.ctg_workplaceid == workplaceId);

    let startDate= this.convertDateToNgbDate(this.listWorkplaces[idx].ctg_datefrom);
    let endDate= this.convertDateToNgbDate(this.listWorkplaces[idx].ctg_dateto);

    this.createNewWorkplaceForm.setValue({
      employer: this.listWorkplaces[idx].ctg_name,
      workplaceStartDate: startDate,
      workplaceEndDate: endDate,
      workplaceId: this.listWorkplaces[idx].ctg_workplaceid,
    }); 

    this.spinner.hide();
  }

  convertDateToNgbDate(dateUtc: string | undefined) {
    let targetDate: NgbDateStruct = { day:0, month: 0, year: 0};
    if (!dateUtc) {
      return targetDate;
    }
    
    let sDate = moment(dateUtc);
    if (sDate != null) {
      targetDate = {
        day: sDate.date(), 
        month: sDate.month() + 1, 
        year: sDate.year()
      };
    }

    return targetDate;
  }

  removeWorkplace(workplaceId: string| undefined) {
    if (!workplaceId)
      return;

    // Display confirmation
    let idx = this.listWorkplaces.findIndex(x => x.ctg_workplaceid == workplaceId);
    if (idx > -1) {
      this.listWorkplaces[idx].isEdited = true;
      this.editModeWorkplace = true;
    }
  }

  confirmRemoveWorkplace(workplaceId: string| undefined) {
    if (!workplaceId)
      return;

    this.spinner.show();
    this.workplaceService.TerminateWorkplace(workplaceId).subscribe({
      error: (err: any) => {
          this.spinner.hide();
          console.error("errorMessage: " + err);
        },
        complete: () => {
          this.WorkingInitialization();
          this.isDisplayEditDialog = false;
          this.editModeWorkplace = false;
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 1200);
        },
    });
  }

  cancelRemoveWorkplace(workplaceId: string| undefined) {
    if (!workplaceId)
      return;

    // Display confirmation
    let idx = this.listWorkplaces.findIndex(x => x.ctg_workplaceid == workplaceId);
    if (idx > -1) {
      this.listWorkplaces[idx].isEdited = false;
      this.editModeWorkplace = false;
    }
  }

  onSaveDataWorkplace() {
    this.isValidSubmit = true;

    let workplaceName: string = this.createNewWorkplaceForm.value.employer;
    let workplaceStartDate: NgbDateStruct = this.createNewWorkplaceForm.value.workplaceStartDate;
    let workplaceEndDate: NgbDateStruct = this.createNewWorkplaceForm.value.workplaceEndDate;
    let workplaceId: string = this.createNewWorkplaceForm.value.workplaceId;

    if (workplaceName == '' || workplaceStartDate.day == 0) {
      this.isValidSubmit = false;
    }
    else {
      this.spinner.show();
      const input = document.getElementById('btnSaveWorkplace') as HTMLInputElement;
      
      let workplaceStartDateStr = new Date(workplaceStartDate.year, workplaceStartDate.month - 1, workplaceStartDate.day, 12).toISOString();
      let workplaceEndDateStr = '';
      if (workplaceEndDate && workplaceEndDate.day != 0) {
        workplaceEndDateStr = new Date(workplaceEndDate.year, workplaceEndDate.month - 1, workplaceEndDate.day, 12).toISOString();
      }

      this.workplaceService.upsertWorkplace(this.userId, workplaceId, workplaceName, workplaceStartDateStr, workplaceEndDateStr).subscribe({
        next: () => {
          input.disabled = false;
        },
        error: (err: any) => {
          this.spinner.hide();
          input.disabled = false;
          console.error("errorMessage: " + err);
        },
        complete: () => {
          this.WorkingInitialization();
          this.isDisplayEditDialog = false;
          this.editModeWorkplace = false;
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 1200);
        },
      });
    }
  }
}
