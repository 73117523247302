<div class="menu-icon" (click)="openMenu()">
  <i class="{{menu_icon}}"></i>
</div>
<div class="desktop-menu" [class.mobile_menu]="menuValue">
  <!--ul class="top-level-menu">
      <li *ngIf="isAuthorized" (click)="closeMenu()">
        <a href="/mypage" class="text-white">Min side</a>
      </li>
      <li *ngIf="isAuthorized && this.memberGrpMenu && this.memberGrpMenu.length > 0" (click)="closeMenu()">
        <a [routerLink]="['/membergroup']" class="text-white">Member Group &dtrif;</a>
        <ul class="second-level-menu" *ngFor="let menu of this.memberGrpMenu">
          <li>
            <a href="#{{menu.memberGroupId}}">{{ menu.memberGroupName}}</a>
            <ul class="third-level-menu" *ngIf="menu.childGroup && menu.childGroup.length > 0" >
              <ng-container *ngFor="let menusub of menu.childGroup">
                <li><a href="#{{menusub.memberGroupId}}">{{ menusub.memberGroupName }}</a></li>
              </ng-container>
            </ul>
          </li>
        </ul>
      </li>
      <li *ngIf="isAuthorized"(click)="closeMenu()" >
        <a [routerLink]="['/inbox']" class="text-white">Innboks ({{inboxCount}})</a>
      </li>
      <li *ngIf="isAuthorized" (click)="closeMenu()">
        <a [routerLink]="['/membershipbenefits']" class="text-white">Medlemsfordeler</a>
      </li>
      <li *ngIf="isAuthorized"(click)="closeMenu()" >
        <a [routerLink]="['/course']" class="text-white">Kurs</a>
      </li>
      <li>
        <a class="border rounded-5 nav-link text-black bg-white logout-btn" role="button" *ngIf="isAuthorized" 
          (click)="inLogOut()" href="">Logg ut</a>

        <a class="border rounded-5 nav-link text-black bg-white logout-btn" role="button" *ngIf="!isAuthorized"
          href="/login">Logg inn</a>
      </li>
  </!--ul-->

  <ul class="multilevel-dropdown-menu">
      <li *ngIf="isAuthorized" (click)="closeMenu()">
        <a href="/mypage" class="text-white">Min side</a>
      </li>
      <li *ngIf="isAuthorized && this.memberGrpMenu && this.memberGrpMenu.length > 0" (click)="closeMenu()" class="parent">
        <a [routerLink]="['/membergroup']" class="text-white">Medlemsgruppe &dtrif;</a>
        <ul class="child second-level-menu" *ngFor="let menu of this.memberGrpMenu">
          <li class="parent ">
            <a routerLink="/membergroup/{{menu.memberGroupId}}" [innerHTML]="rederMenuText(menu)"></a>
            <ul class="child third-level-menu" *ngIf="menu.childGroup && menu.childGroup.length > 0" >
              <ng-container *ngFor="let menusub of menu.childGroup">
                <li><a routerLink="/membergroup/{{menusub.memberGroupId}}">{{ menusub.memberGroupName }}</a></li>
              </ng-container>
            </ul>
          </li>
        </ul>
      </li>
      <li *ngIf="isAuthorized"(click)="closeMenu()" >
        <a [routerLink]="['/inbox']" class="text-white">Innboks ({{inboxCount}})</a>
      </li>
      <li *ngIf="isAuthorized" (click)="closeMenu()">
        <a [routerLink]="['/membershipbenefits']" class="text-white">Medlemsfordeler</a>
      </li>
      <li *ngIf="isAuthorized"(click)="closeMenu()" >
        <a [routerLink]="['/course']" class="text-white">Kurs</a>
      </li>
      <li>
        <a class="border rounded-5 nav-link text-black bg-white logout-btn" role="button" *ngIf="isAuthorized" 
          (click)="inLogOut()" href="">Logg ut</a>

        <a class="border rounded-5 nav-link text-black bg-white logout-btn" role="button" *ngIf="!isAuthorized"
          href="/login">Logg inn</a>
      </li>
  </ul>
</div>
