import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Directive, ElementRef, Inject, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { BankidService } from '@app/xServices/bankid.service';
import { EncryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { environment } from '@environments/environment';


@Component({
  selector: 'ctg-bank-id',
  template: '',
  styleUrls: ['./bank-id.component.scss']
})

export class BankIdComponent implements OnInit {

  constructor( @Inject(DOCUMENT) private document: Document,  private encryptPipe: EncryptPipe) {


    let BankIdUrl=environment.BankIdUrl;
    let Client_id= environment.client_id;
    let Redirect_uri = environment.redirect_uri;
    let ui_locale = "en"

    let h = BankIdUrl+"precheck/auth?client_id="+Client_id+"&redirect_uri="+Redirect_uri+"&response_type=code&scope=profile&ui_locales=nb";
    this.document.location.href = `${BankIdUrl}precheck/auth?client_id=${Client_id}&redirect_uri=${Redirect_uri}&response_type=code&scope=profile&ui_locales=${ui_locale}`;
  }

  ngOnInit(): void {}


  public GetBankIdUrl(parameter: string) {


      try {
        let stringData = JSON.stringify("sdcsdc");
        let encryptedData = this.encryptPipe.transform(stringData)
        localStorage.setItem("adam", encryptedData);
      } catch (error) {
        console.error("Can not set personID to cache");
      }
  };

}
