export class UserStatusModel {
  familyName!: string;  //"STATE_COMPLETED",
  givenName!: string; // "Nordmann, Ola",
  birthdate: string = new Date().toISOString(); // "1982-03-27",
  personID!: string; // "9578-5997-4-354619",
  token!: string;

}
  /*
    {
    "givenName": "Carl Erik",
    "familyName": "Melhus",
    "birthdate": "1982-03-27",
    "personID": "9578-6000-4-166376"
    }


    */
