import { Component } from '@angular/core';
import { UserService } from '@app/xServices/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { UntypedFormBuilder,  UntypedFormGroup } from '@angular/forms';

import { Education } from '@app/xModels/EducationInfo';
import { BaseModel } from '@app/xModels/BaseModel';

import { StoreManageService } from '@app/xServices/StoreManageService.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { EducationService } from '@app/xServices/education.service';

@Component({
  selector: 'app-user-education',
  templateUrl: './user-education.component.html',
  styleUrls: ['./user-education.component.scss']
})
export class UserEducationComponent {

  public userId: string = "";
  public createNewEducationForm!: UntypedFormGroup;
  workType:string="";
  listEducations: Education[] = [];

  isDisplayEditDialog: boolean = false;
  editModeEducation: boolean = false;
  btnSaveEducationDisable: boolean = false;
  isValidSubmit: boolean = true;
  
  constructor(private userService: UserService,  
    private spinner: NgxSpinnerService, 
    private router: Router,
    public fb: UntypedFormBuilder,
    private storeManageService:StoreManageService,
    private educationService: EducationService,
  ) {
    this.spinner.show();
    this.userId = this.storeManageService.GetContactId();
    this.EducationInitialization();
  }

  ngOnInit(): void {    
    this.createNewEducationForm = this.fb.group({
      employer: [''],
      educationStartDate: [''],
      educationEndDate: [''],
      educationId: [''],
    });
  }

  private EducationInitialization() {
    const futureDate = new Date(8640000000000000);

    if (this.userId == null || this.userId == undefined) {
      this.router.navigate(['/registration']);
    } else {
      try {
        this.userService.GetAllEducations(this.userId).subscribe({
          next: (data: BaseModel<Education[]> ) => {
            if (data) {
              this.listEducations = data.responseData.sort((a, b) => {
                let dateA = a.ctg_datefrom ? new Date(a.ctg_datefrom) : futureDate;
                let dateB = b.ctg_datefrom ? new Date(b.ctg_datefrom) : futureDate;
                return dateB.getTime() - dateA.getTime();
              });
            }            
          },
          error: (err) => {
            console.error("errorMessage: " + err);
            this.spinner.hide();
          },
          complete:()=>{
            //this.workType=this.getLabelFromEducationPicklist(this.currentEducation.ctg_type);
          },
        });
      } catch (error) {
        console.error("Could not retrieve education information");
      }
    }
  }

  newEducation() {
    this.isDisplayEditDialog = true;

    this.createNewEducationForm.setValue({
      employer: "",
      educationStartDate: { day:0, month: 0, year: 0},
      educationEndDate: { day:0, month: 0, year: 0},
      educationId: "",
    }); 
  }

  editEducation(educationId: string | undefined) {
    if (!educationId)
      return;

    this.spinner.show();
    this.isDisplayEditDialog = true;
    let idx = this.listEducations.findIndex(x => x.ctg_educationid == educationId);

    let startDate= this.convertDateToNgbDate(this.listEducations[idx].ctg_datefrom);
    let endDate= this.convertDateToNgbDate(this.listEducations[idx].ctg_dateto);

    this.createNewEducationForm.setValue({
      employer: this.listEducations[idx].ctg_name,
      educationStartDate: startDate,
      educationEndDate: endDate,
      educationId: this.listEducations[idx].ctg_educationid,
    }); 

    this.spinner.hide();
  }

  convertDateToNgbDate(dateUtc: string | undefined) {
    let targetDate: NgbDateStruct = { day:0, month: 0, year: 0};
    if (!dateUtc) {
      return targetDate;
    }
    
    let sDate = moment(dateUtc);
    if (sDate != null) {
      targetDate = {
        day: sDate.date(), 
        month: sDate.month() + 1, 
        year: sDate.year()
      };
    }

    return targetDate;
  }

  removeEducation(educationId: string| undefined) {
    if (!educationId)
      return;

    // Display confirmation
    let idx = this.listEducations.findIndex(x => x.ctg_educationid == educationId);
    if (idx > -1) {
      this.listEducations[idx].isEdited = true;
      this.editModeEducation = true;
    }
  }
  confirmRemoveEducation(educationId: string| undefined) {
    if (!educationId)
      return;

    this.spinner.show();
    this.educationService.TerminateEducation(educationId).subscribe({
      error: (err: any) => {
          this.spinner.hide();
          console.error("errorMessage: " + err);
        },
        complete: () => {
          this.EducationInitialization();
          this.isDisplayEditDialog = false;
          this.editModeEducation = false;
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 1200);
        },
    });
  }

  cancelRemoveEducation(educationId: string| undefined) {
    if (!educationId)
      return;

    // Display confirmation
    let idx = this.listEducations.findIndex(x => x.ctg_educationid == educationId);
    if (idx > -1) {
      this.listEducations[idx].isEdited = false;
      this.editModeEducation = false;
    }
  }

  onSaveDataEducation() {
    this.isValidSubmit = true;

    let educationName: string = this.createNewEducationForm.value.employer;
    let educationStartDate: NgbDateStruct = this.createNewEducationForm.value.educationStartDate;
    let educationEndDate: NgbDateStruct = this.createNewEducationForm.value.educationEndDate;
    let educationId: string = this.createNewEducationForm.value.educationId;

    if (educationName == '' || educationStartDate.day == 0) {
      this.isValidSubmit = false;
    }
    else {
      this.spinner.show();
      const input = document.getElementById('btnSaveEducation') as HTMLInputElement;
      
      let educationStartDateStr = new Date(educationStartDate.year, educationStartDate.month - 1, educationStartDate.day, 12).toISOString();
      let educationEndDateStr = '';
      if (educationEndDate && educationEndDate.day != 0) {
        educationEndDateStr = new Date(educationEndDate.year, educationEndDate.month - 1, educationEndDate.day, 12).toISOString();
      }

      this.educationService.upsertEducation(this.userId, educationId, educationName, educationStartDateStr, educationEndDateStr).subscribe({
        next: () => {
          input.disabled = false;
        },
        error: (err: any) => {
          this.spinner.hide();
          input.disabled = false;
          console.error("errorMessage: " + err);
        },
        complete: () => {
          this.EducationInitialization();
          this.isDisplayEditDialog = false;
          this.editModeEducation = false;
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 1200);
        },
      });
    }
  }
}
