import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseModel } from '@app/xModels/BaseModel';
import { IPicklistCategories, IPicklistResponse } from '@app/xModels/CrmModel';
import { FileGetApiModel } from '@app/xModels/FileGetApiModel';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
//import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })


export class CrmService {
  private httpOptions = {
    headers: new HttpHeaders().append('Content-Type', 'application/json; charset=utf-8')
      .append('Access-Control-Allow-Headers', 'Content-Type')
    //.append('Access-Control-Allow-Headers', 'Content-Type')
    //  .set('Access-Control-Allow-Origin',"http://localhost:4200/")
  };
  //let headers = new HttpHeaders().set('access-control-allow-origin',"http://localhost:8080/");
  constructor(private http: HttpClient) { }

  public get<T>(endpoint: string): Observable<T> {
    return this.http.get<T>(environment.apiUrl + '/' + endpoint);
  }

  public post<T>(endpoint: string, body: any, returnData: boolean = true): Observable<T> {

    //TODO ORKHAN: Should be implemented in another way?
    if (returnData == true) {
      this.httpOptions = {
        headers: new HttpHeaders().append('Content-Type', 'application/json; charset=utf-8')
          .append('Access-Control-Allow-Headers', 'Content-Type')
          .append('Prefer', 'return=representation')
      };
    }
    return this.http.post<T>(environment.apiUrl + '/' + endpoint, body, this.httpOptions);
  }

  public patch<T>(endpoint: string, body: any, returnData: boolean = true): Observable<T> {

    //ORKHAN: Should be implemented in another way?
    if (returnData == true) {
      this.httpOptions = {
        headers: new HttpHeaders().append('Content-Type', 'application/json; charset=utf-8')
          .append('Access-Control-Allow-Headers', 'Content-Type')
          .append('Prefer', 'return=representation')
      };
    }
    return this.http.patch<T>(environment.apiUrl + '/' + endpoint, body, this.httpOptions);
  }

  public delete<T>(endpoint: string): Observable<T> {
    return this.http.delete<T>(environment.apiUrl + '/' + endpoint, this.httpOptions);
  }

  /**
   * 
   * @param endpoint string for url
   * @param methode "post" or  "get"
   * @param body body meant for postmethod
   * @returns Blob
   */
  public getBlob(endpoint: string, method : 'get' | 'post', body : any) : Observable<Blob> {
if (method == "post"){
  return this.http.post(environment.apiUrl + '/' + endpoint, body, { responseType: 'blob' });

} else if (method == "get"){
    return this.http.get(environment.apiUrl + '/' + endpoint, { responseType: 'blob' });
  }
  else{
    throw new Error("No method is chosen for getblob function")
  }
}
  

  


  // picklistCategories: IPicklistCategories[] = [];
  /*
   * 
   * @param entity 
   * @param fieldName 
   * @returns  "label" and "value" array
   */
  public GetPicklistValues(_entity: string, _fieldName: string, _multiSelect : boolean | void): any {
    let picklistCategories : IPicklistCategories[] = [];
    let _metaDataType = "PicklistAttributeMetadata"
    if (_multiSelect){
      _metaDataType = "MultiSelectPicklistAttributeMetadata";
    }

    let params = new (class MySendable{
      entity:string =_entity;
      fieldName: string=_fieldName;
      metaDataType: string=_metaDataType;
    });

    //returns metadata and converts it to array with label and value
    //? let endpoint = "EntityDefinitions(LogicalName='" + entity + "')/Attributes(LogicalName='" + fieldName + "')/Microsoft.Dynamics.CRM."+ metaDataType+"?$select=LogicalName&$expand=GlobalOptionSet($select=Options)";
    let endpoint = "Picklist"
    return this.post<BaseModel<IPicklistResponse>>(endpoint,params).pipe(
      map((res: BaseModel<IPicklistResponse>) => {        
        let picklistCategories = res.responseData;        
        return picklistCategories;
      }))
  }
}
