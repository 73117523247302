
<div class="container h-75">
  <div class="row">
    <div class="col-12 mt-5 text-start course-container text-center">
      <div class="row">
        <div class="col-12">
          <i class="bi bi-check2-circle thanks-checkmark"></i>
          <h2 class="message-text-header">Takk for din påmelding på kurset. Vi har mottat følgende informasjon.</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          
          <div class="course-container">
            <div class="row">
              <div class="col-12 text-left"><h4>Kursinformasjon</h4></div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="course-data-block">
                  <div class="course-block">
                    <div class="course-info-title">Name</div>
                    <div>{{ data?.courseName }}</div>
                  </div>
                  
                  <div class="course-block" *ngIf="data?.isPayable">
                    <div class="course-info-title">Price</div>
                    <div>{{ data?.price | number:'2.2-2' }}</div>
                  </div>
                  <div class="course-block" *ngIf="data?.duration">
                    <div class="course-info-title">Varighet</div>
                    <div>{{ data?.duration }}</div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="course-data-block">
                  <div class="course-block">
                    <div class="course-info-title">Tidsperiode</div>
                    <div>{{ displayDateRange(data) }}</div>
                  </div>
                  <div class="course-block" *ngIf="data?.locationName">
                    <div class="course-info-title">Sted</div>
                    <div>{{ data?.locationName }}</div>
                  </div>
                  <div class="course-block" *ngIf="data?.maxParticipants">
                    <div class="course-info-title">Maks deltakere</div>
                    <div>{{ data?.maxParticipants }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button
                  class="btn btn-primary col-3 mx-auto"
                  [routerLink]="['/course']"
                >
                Kurs
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Laster... </p>
</ngx-spinner>
