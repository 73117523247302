
<div class="container h-75 pt-4">
  <div class="row">
    <div class="col-12">
      <span class="fs-4">{{ groupInfo?.memberGroupName }}</span>
    </div>
  </div>
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12">
        <label for="nInput3" class="form-label form-general-lable mt-3">Status</label>
        <select class="form-select" [(ngModel)]="filterStatus" (change)="filterChange()" id="nInput3">
          <option value="all">Aktive medlemskap</option>
          <option value="new">Nye medlemskap</option>
          <option value="inactive">Kansellerte medlemskap</option>
        </select>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-12 text-right pt-5">
        <app-dialog-sendemail
          [memberOnGroupObject]="selectedList"
          [appDisable]="!selectedList || (selectedList && selectedList.length == 0)"
          (onSubmit)="submitEmail($event)"
        >
        </app-dialog-sendemail>
        <!--form>
          <button class="btn btn-primary" (click)="submitEmail()">Send e-post</button>
        </!--form-->
      </div>
    </div>
    <!--Debug table-->
    <div class="row" *ngIf="false"> 
      <div class="col-12">
        <table>
          <tr *ngFor="let sl of selectedList">
            <td>{{ sl.memberContactId }}</td>
            <td>{{ sl.memberContactName }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive pt-4">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>
                  <input type="checkbox" [(ngModel)]="isSelectedALl" (change)="selectAllContact($event)" />
                </th>
                <th class="sort" data-sort="memberContactName" scope="col" >Navn</th>
                <th class="sort" data-sort="" scope="col" >Verv</th>
                <th class="sort text-center" data-sort="fromDate" scope="col" >Fra dato</th>
                <th class="sort text-center" data-sort="toDate" scope="col" >Til dato</th>
                <th class="sort" data-sort="memberContactEmail" scope="col" >Epost</th>
                <th class="sort" data-sort="memberContactPhone" scope="col" >Mobiltelefon</th>
                <th class="sort" data-sort="" scope="col" >Merknader</th>
              </tr>
            </thead>
            <tbody class="list" >
              <tr *ngFor="let member of memberList">
                <td>
                    <input type="checkbox" [(ngModel)]="member.isSelected" (change)="changeCheckBox($event, member)" />
                </td>
                <td>{{ member.memberContactName }}</td>
                <td>{{ member.memberPostName }}</td>
                <td class="text-center">{{ member.fromDate | date:'dd.MM.yy' }}</td>
                <td class="text-center">{{ member.toDate | date:'dd.MM.yy' }}</td>
                <td>{{ member.memberContactEmail }}</td>
                <td>{{ member.memberContactPhone }}</td>
                <td>{{ member.membershipStatus }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer py-4">
          <nav aria-label="...">
              <pagination [totalItems]="totalItems"
                  [itemsPerPage]="configTableMaxRow"
                  [(ngModel)]="currentPage"
                  (pageChanged)="pageChanged($event)"
                  class="justify-content-end mb-0"
                  [maxSize]="5"
                  [boundaryLinks]="true"
                  [disabled]="disabled"
                  [customPreviousTemplate]="prevTemplate"
                  [customNextTemplate]="nextTemplate"
                  firstText="Først"
                  lastText="Sist">
              </pagination>
              <ng-template #nextTemplate let-currentPage="currentPage">
                  <ng-container *ngIf="!disabled">
                    <i class='bi bi-caret-right'></i>
                  </ng-container>
                </ng-template>
    
                <ng-template #prevTemplate let-currentPage="currentPage">
                  <ng-container *ngIf="!disabled">
                    <i class='bi bi-caret-left'></i>
                  </ng-container>
                </ng-template>
            </nav>
        </div>
      </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Laster... </p>
</ngx-spinner>
