import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import { CourseInfo, CourseRegister } from '@app/xModels/CourseModel';
import { IPicklistCategories } from '@app/xModels/CrmModel';
import { IFileGetApiModel, FileGetApiModel } from '@app/xModels/FileGetApiModel';
import { IKategoriMedlemsFoldId, IMembershipBenefit, IMembershipBenefitCategory } from '@app/xModels/UserMembershipBenefits';
import { IUserNews } from '@app/xModels/UserNews';
import { CourseService } from '@app/xServices/course.service';
import { CrmService } from '@app/xServices/crm.service';
import { DecryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseModel, OptionSetItem } from '@app/xModels/BaseModel';
import { StoreManageService } from '@app/xServices/StoreManageService.service';
import swal from 'sweetalert2';
import { UserService } from '@app/xServices/user.service';
import { UserContacts } from '@app/xModels/UserContacts';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-courseinfo',
  templateUrl: './user-courseinfo.component.html',
  styleUrls: ['./user-courseinfo.component.scss']
})


export class UserCourseInfoComponent implements OnInit {
  private sub: any;
  courseId: string = '';
  data?: CourseInfo;
  mode: string = "read";
  public userInfo?: UserContacts;
  public registerCourseForm!: UntypedFormGroup;
  public registerModel: CourseRegister | undefined;
  public dInvoiceVia: number = 961700000;
  public invoiceOption: OptionSetItem[] = [
    { label:'E-post', value: 961700000},
    { label:'eFaktura ', value: 961700002},
    { label:'Vipps MobilePay', value: 961700004},
  ];
  
  constructor(private service: CourseService, 
    private sanitizer: DomSanitizer, 
    private spinner: NgxSpinnerService,
    private router : Router,
    private route: ActivatedRoute, 
    private datepipe: DatePipe,
    private storeManageService:StoreManageService,
    private userService: UserService,
    public fb: UntypedFormBuilder ) {
      this.spinner.show();
      this.sub = this.route.params.subscribe(params => {
        this.courseId = params['courseid']; 
      });
      this.GetInitialData();
  }

  ngOnInit() { 
    this.registerCourseForm = this.fb.group({
      sendInvoiceVia:  [this.dInvoiceVia, Validators.required],
      txtInvoiceReference: ['', Validators.required],
    });
  }

  private GetInitialData() {
    this.service.getCourse(this.courseId).subscribe({
      next: (data: BaseModel<CourseInfo>) => {
        if (!!data) {
          this.data = data.responseData;
        } 
      },
      complete: () => {
        this.spinner.hide();
      }
    });

    this.userService.GetUserInfo(this.storeManageService.GetContactId()).subscribe(res =>
      {
        this.userInfo = res.responseData;
      });
  }

  displayDateRange(counse?: CourseInfo): string {
    if (!counse) {
      return '';
    }

    let fdate = counse.dateFrom ? new Date(counse.dateFrom).toLocaleDateString() : '';
    let edate = counse.dateTo ? new Date(counse.dateTo).toLocaleDateString() : '';
    if (fdate == edate) {
      return this.datepipe.transform(counse.dateFrom, 'dd.MM.yyyy HH:mm')! + ' - ' + this.datepipe.transform(counse.dateTo, 'HH:mm');
    }
    else {
      return `${this.datepipe.transform(counse.dateFrom, 'dd.MM.yyyy HH:mm')} - ${this.datepipe.transform(counse.dateTo, 'dd.MM.yyyy HH:mm')}`;
    }
  }
  
  displayTime(d: Date): string {
    return d.getHours().toString() + ':' + d.getMinutes().toString();
  }

  cancelRegister() {
    this.mode = 'read';
  }

  showRegister() {
    if (this.data?.isPayable) {
      // Continue Step
      this.mode = 'register';
      // Check Default register form
      // 1. Send Invoice via (Email)
      if (this.userInfo && this.userInfo.sendInvoiceVia && this.invoiceOption.some(a => a.value == this.userInfo!.sendInvoiceVia)) {
        this.registerCourseForm.get("sendInvoiceVia")?.setValue(this.userInfo.sendInvoiceVia);
      }
    }
    else {
      // Automatic register
      let registerModel: CourseRegister = {
        contactId: this.userInfo?.contactid!,
        courseId: this.courseId,
        isRequirePayment: false,
      };
  
      this.registerCourse(registerModel);
    }
  }

  getFormValidationErrors(): boolean {
    var res = true;
    Object.keys(this.registerCourseForm.controls).forEach(key => {
      const controlErrors: ValidationErrors | null = this.registerCourseForm.get(key)?.errors ?? null;
      if (controlErrors != null) {
        //this.registerCourseForm.get(key).markAsDirty();
        res = false;
        Object.keys(controlErrors).forEach(keyError => {
         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });

    return res;
  }

  submitRegister() {
    //let userId = this.storeManageService.GetContactId();
    let registerModel: CourseRegister = {
      sendInvoiceVia: this.registerCourseForm.value.sendInvoiceVia,
      contactId: this.userInfo?.contactid!,
      courseId: this.courseId,
      invoiceReference: this.registerCourseForm.value.txtInvoiceReference,
      isRequirePayment: true
    };
    if (this.registerCourseForm.invalid) { 
      Object.keys( this.registerCourseForm.controls).forEach(key => {
        this.registerCourseForm.controls[key].markAsDirty();
      });
      return;
    }

    /*if (!this.getFormValidationErrors())
    {
      return;
    }*/
    this.registerCourse(registerModel);
  }

  registerCourse(registerModel: CourseRegister) {
    this.spinner.show();
    this.service.registerCourse(registerModel).subscribe(res => {
      this.spinner.hide();
      if(res && res.responseData != '') {
        this.router.navigate(['/course', 'result',this.courseId, res.responseData]);
      }
      else {
        swal.fire({
          title: 'Error',
          text: res.description,
          icon: 'warning',
          confirmButtonText: 'OK',
        });
      }
    });
  }
}
