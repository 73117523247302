<ng-template #sendMailDialog let-c="close" let-d="dismiss" width="600">
    <div class="modal-header">
        <h4 class="modal-title">Send e-post</h4>
        <button type="button" class="close" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <label for="txtRecipient" class="form-label small">E-post</label>
                <tag-input [(ngModel)]="memberOnGroupObject"
                    [identifyBy]="'memberContactId'"
                    [displayBy]="'memberContactName'"
                    [editable]="false"
                    [removable]="false"
                    [disable]="false"
                    [maxItems]="memberOnGroupObject?.length ?? 0"
                    class="form-control"
                    id="txtRecipient" 
                    >
                </tag-input>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label for="txtEmailSubject" class="form-label small">Emne</label>
                <input [(ngModel)]="emailSubject" class="form-control" id="txtEmailSubject" [ngClass]="invalidSubjectFieldClass" />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label for="txtEmailBody" class="form-label small">Melding</label>
                <textarea [(ngModel)]="emailBody" class="form-control" id="txtEmailBody" rows="10"  [ngClass]="invalidBodyFieldClass"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-raised btn-primary" (click)="submit()">Send</button>
        <button type="button" class="btn btn-raised" (click)="dismiss()">Annuller</button>
    </div>
</ng-template>

<button class="btn btn-primary" [disabled]="appDisable" (click)="open(sendMailDialog)">Send e-post</button>