import { Injectable } from "@angular/core";
import { Client } from "@vippsmobilepay/sdk";
import { RootService } from "./root.service";
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CrmService } from "./crm.service";
import { map, Observable } from "rxjs";
import { BaseModel } from "@app/xModels/BaseModel";
import { CourseInfo, CourseRegister, CourseResponse } from "@app/xModels/CourseModel";

@Injectable({
    providedIn: 'root'
})
export class CourseService {

    constructor(private crmService: CrmService) { }

    public getCourses(): Observable<BaseModel<CourseResponse>> {
        return this.crmService.get<BaseModel<CourseResponse>>("course/getcourse");
    }

    public getCourse(courseId: string): Observable<BaseModel<CourseInfo>> {
        let _url="course/GetCourseInfo/"+courseId;    
        return this.crmService.get<BaseModel<CourseInfo>>(_url);
    }

    public registerCourse(req: CourseRegister): Observable<BaseModel<string>> {
        let _url="course/RegisterCourse";    
        return this.crmService.post<BaseModel<string>>(_url, req);
    }

    public UnRegisterCourse(courseId: string): Observable<BaseModel<string>> {
        let req: CourseRegister = {
            contactId: '',
            courseId: courseId
        };
        let _url="course/UnRegister";    
        return this.crmService.post<BaseModel<string>>(_url, req);
    }
}