
<div class="container h-75">
  <div class="row">
    <div class="col-12 bg-primary-header  mt-5  text-start ps-4">
      <p class="fs-4 pt-3 fw-normal">Innboks</p>
    </div>
  </div>
  <div class="row mt-3 ">
    <!--Left side-->
    <div class="col-md-4 ">

      <ul class="news-inbox">
        <li class="news-inbox-item" 
          *ngFor="let item of inboxItems" 
          [ngClass]="{
            'message-links-readed': item.readedNews, 
            'message-links': !item.readedNews,
            'message-focus': item.newsSelected
            }" 
          (click)="openForRead(item);"
          >
          <div class="news-inbox-container">
            <h5>{{item.name}}</h5>
            <span>{{item.mainText}}</span>

            
          </div>

          <i class="news-inbox-icon bi me-2 bi-lg" [ngClass]="readItems && item.newsid == readItems.newsid && openToRead ? ' bi-caret-right-fill ' : 'bi-caret-down-fill  icon-additional'">
          </i>
        </li>
      </ul>
    </div>
    <!--Right side-->
    <div class="col-md-8 mb-3 inbox-news-container" [class.mobile-news]="openToRead" *ngIf="openToRead" (click)="closeMobileNews()">
      <div class="row message-text-area" (click)="$event.stopPropagation()">
        <div class="message-content" *ngIf="readItems">
          <div class="message-title">
            <span class="message-text-header">{{readItems.detailHeading}}</span>
            <div class="news-detail-icon" (click)="closeNews()">
              <i class="bi bi-x"></i>
            </div>
          </div>
          
          <div class="clear"></div>
          <img *ngIf="readItems && readItems.banner" 
            [src]="readItems.banner.picture| base64: readItems.banner.pictureContentType "
            [class]="'image ' + (readItems && readItems.banner.picture == null ? 'd-none' : '')" 
            [width]="readItems.banner.imageWidth" 
            [height]="readItems.banner.imageHeight"
            >
          <div *ngIf="readItems && readItems.detailText"  [innerHTML]="readItems.detailText" class="mt-3"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Laster... </p>
</ngx-spinner>
