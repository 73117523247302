<div class="container-bg-color">
    <div class="container h-100 p-5">
      <ngb-alert type="danger" *ngIf="displayStatus" [dismissible]="false" class="card text-center">
        <label [innerHTML]="displayMessageStatus"></label>
      </ngb-alert>

      <div class="card reset-password-form-card mt-3" *ngIf="!isLoading">
        <!-- Internal: Reset password -->
        <div class="card-body p-5" *ngIf="((isExternalReset && isTokenValid) || !isExternalReset) && mode != 'email'">
          <h4 class="card-title fw-bold mb-4">Tilbakestill passord</h4>
          <form
            class="form"
            [formGroup]="resetPasswordForm"
            (ngSubmit)="onSubmitResetPassword()"
          >
            <div class="mb-3" *ngIf="isRequireCurrentPassword">
                <label for="currentPassword">Passord: </label>
                <input
                    id="currentPassword"
                    type="password"
                    class="form-control"
                    formControlName="currentPassword"
                    #npw
                />
                <div
                    *ngIf="
                    currentPassword.invalid &&
                    (currentPassword.dirty || currentPassword.touched)
                "
                class="form-text text-danger"
                >
                <div *ngIf="currentPassword.errors?.['required']">
                  Passord er påkrevet
                </div>
                </div>
            </div>
            <div class="mb-3">
              <label for="newPassword">Nytt passord: </label>
              <input
                id="newPassword"
                type="password"
                class="form-control"
                formControlName="newPassword"
                #pw
              />
              <div
                *ngIf="
                  newPassword.invalid &&
                  (newPassword.dirty || newPassword.touched)
                "
                class="form-text text-danger"
              >
                <div *ngIf="newPassword.errors?.['required']">
                  Passord er påkrevet
                </div>
                <div *ngIf="newPassword.errors?.['pattern']">
                  Passord må inneholde minst ett tall, et spesialtegn, en stor og en liten bokstav, og ha minst tolv tegn. Passordet kan ikke inneholde mellomrom.
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="confirmPassword">Nytt passord (bekreftelse): </label>
              <input
                id="confirmPassword"
                type="password"
                class="form-control"
                [ngClass]="confirmPasswordClass"
                formControlName="confirmPassword"
                #cpw
              />
              <div
                *ngIf="
                  confirmPassword.invalid &&
                  (confirmPassword.dirty || confirmPassword.touched)
                "
                class="form-text text-danger"
              >
                <div *ngIf="confirmPassword.errors?.['required']">
                  Passord er påkrevet
                </div>
                <div *ngIf="confirmPassword.errors?.['pattern']">
                  Passord må inneholde minst ett tall, et spesialtegn, en stor og en liten bokstav, og ha minst tolv tegn. Passordet kan ikke inneholde mellomrom.
                </div>
                <div *ngIf="confirmPassword.errors?.['confirmedValidator']">
                  Passord stemte ikke
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <button
                type="submit"
                [disabled]="resetPasswordForm.invalid"
                class="btn btn-primary col-5 mx-auto"
              >
              Tilbakestill passord
              </button>
              <button
                type="button"
                class="btn btn-light col-5 mx-auto"
                [routerLink]="['/login']"
              >
              Tilbake
              </button>
            </div>
          </form>
        </div>

        <!-- External: Email Reset password request -->
        <div class="card-body p-5" *ngIf="mode == 'email'">
            <h4 class="card-title fw-bold mb-4">Glemt passord</h4>
            <h5>Skriv inn e-postadressen din, så sender vi deg en lenke for å tilbakestille passordet ditt</h5>
            <form
            class="form"
            [formGroup]="emailRecoveryPasswordForm"
            (ngSubmit)="onSubmitEmailRequest()"
          >
            <div class="mb-3">
                <input
                    id="currentEmail"
                    type="email"
                    class="form-control"
                    formControlName="currentEmail"
                    placeholder="Skriv inn e-postadressen din"
                    #eml
                />
                <div
                    *ngIf="
                    currentEmail.invalid &&
                    (currentEmail.dirty || currentEmail.touched)
                "
                class="form-text text-danger"
                >
                <div *ngIf="currentEmail.errors?.['required']">
                  E-postadresse er påkrevet
                </div>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <button
                    type="submit"
                    [disabled]="emailRecoveryPasswordForm.invalid"
                    class="btn btn-primary col-5 mx-auto"
                >
                  Tilbakestill passord
                </button>
                <button
                    type="button"
                    class="btn btn-light col-5 mx-auto"
                    [routerLink]="['/login']"
                >
                  Tilbake
                </button>
              </div>
            </form>
        </div>

        <!-- External: Token verify fail -->
        <div class="card-body p-5" *ngIf="isExternalReset && !isTokenValid">
            <h4 class="card-title fw-bold mb-4">Lenken for tilbakestilling av passord er utløpt. Vennligst registrer deg og prøv på nytt.</h4>
            <div class="d-flex align-items-center">
                <button
                  type="button"
                  class="btn btn-light col-5 mx-auto"
                  [routerLink]="['/login']"
                >
                  Tilbake
                </button>
              </div>
        </div>
      </div>
    </div>
  </div>
  
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Laster... </p>
  </ngx-spinner>