import { Injectable } from "@angular/core";
import { Client } from "@vippsmobilepay/sdk";
import { RootService } from "./root.service";
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CrmService } from "./crm.service";
import { map, Observable } from "rxjs";
import { BaseModel } from "@app/xModels/BaseModel";
import { CourseInfo, CourseRegister, CourseResponse } from "@app/xModels/CourseModel";

@Injectable({
    providedIn: 'root'
})
export class ParticipantService {

    constructor(private crmService: CrmService) { }

    public getParticipantInfo(participantId: string): Observable<BaseModel<CourseInfo>> {
        let _url="course/GetParticipantInfo/"+participantId;    
        return this.crmService.get<BaseModel<CourseInfo>>(_url);
    }
}