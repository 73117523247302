<div class="container-fluid">
    <main class="container container-main ">
      <div class=" mb-3 row ">
        <div class="col-12 mt-5 mb-5 text-center">
          <h3>Bekreft dine opplysninger</h3>
        </div>
      </div>
  
      <ngb-alert type="danger" *ngIf="statusType == 'danger'" [dismissible]="false" class="p-5 text-center">
        <label [innerHTML]="displayMessageStatus"></label>
      </ngb-alert>
  
      <ngb-alert type="info" *ngIf="statusType == 'info'" [dismissible]="false" class="p-5 text-center">
        <label [innerHTML]="displayMessageStatus"></label>
      </ngb-alert>

      <ngb-alert type="success" *ngIf="statusType == 'success'" [dismissible]="false" class="p-5 text-center">
        <label [innerHTML]="displayMessageStatus"></label>
      </ngb-alert>
    </main>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
      <p style="color: white"> Laster... </p>
    </ngx-spinner>
  </div>
 
  
  
  