import { DATE_PIPE_DEFAULT_TIMEZONE } from "@angular/common";

export class UserMembership {
  memberType :string="";
  memberNumber:string="";
  memberFrom :string = new Date().toISOString();
  memberTo :string = new Date().toISOString();
  current : boolean = false;
  
  upcoming : boolean = false;
  id: string="";
  invoiceStartDate: string = new Date().toISOString();
  init(o:any){
    this.memberNumber = o.acan_kontakt.acan_kundenummer,
    this.memberFrom = o.acan_fradato,
    this.memberTo = o.toDate,   
    this.memberType = o.acan_vare.acan_name,
    this.current = o.current,
    this.upcoming = o.upcoming,
    this.id = o.acan_medlemskapid,
    this.invoiceStartDate = o.ctg_invoicestartdate
  }
}

export class RequestEndMembership {

}
export class RequestNewMembership {
  fromDate: string = new Date().toISOString();
  invoiceStartDate: string = new Date().toISOString();
  membershipId: string = "";
  contactId: string = "";
}