import { map } from 'rxjs';
import { UserService } from '@app/xServices/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DecryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MembershipService } from '@app/xServices/membership.service';

@Component({
  selector: 'ctg-user-invoices',
  templateUrl: './user-invoices.component.html',
  styleUrls: ['./user-invoices.component.scss']
})
export class UserInvoicesComponent implements OnInit {


  public showHistory : boolean = false;
  public invoiceData : any = [];
  private userId: string = "";
  constructor(private userService: UserService, private membershipService: MembershipService, private spinner: NgxSpinnerService, private router: Router,
    private decryptPipe: DecryptPipe, public fb: FormBuilder) {
    this.spinner.show();
    this.InvoicesInitialization();
    this.spinner.hide();
  }
  ngOnInit(): void { }

  private InvoicesInitialization() {

      this.userService.getInvoiceData().subscribe({
        next: (value: any) => {
          this.invoiceData = value.values;
        },
        error: (err: any) => {
          console.error("errorMessage: " + err);
        }
      });
    

}

public toggleInvoiceHistory(){
  var elems = document.querySelectorAll(".invoice-history.collapse");
  if (this.showHistory){
    var elems = document.querySelectorAll(".invoice-history");
    [].forEach.call(elems, function(el : any) {
        el.classList.add("collapse");
    });
this.showHistory= false;
  }else{
    [].forEach.call(elems, function(el : any) {
        el.classList.remove("collapse");
    });
    this.showHistory= true;
  }
  
}
public downloadPDF(invoiceId : string){
  console.log("download " + invoiceId);
  this.userService.downloadInvoice(invoiceId).subscribe({
    next: (blob: any) => {
//returns invoice
    },
    error: (err: any) => {
      debugger;
      console.error("errorMessage: " + err);
    }
  });
}

}

