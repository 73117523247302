import { Component, OnInit } from '@angular/core';
import {
  IMembershipBenefit,
  IMembershipBenefitCategory,
} from '@app/xModels/UserMembershipBenefits';
import { MembershipService } from '@app/xServices/membership.service';

import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-membershipbenefits',
  templateUrl: './user-membershipbenefits.component.html',
  styleUrls: ['./user-membershipbenefits.component.scss'],
})
export class UserMembershipbenefitsComponent implements OnInit {
  benefits: IMembershipBenefit[] = []; //All membership benefits
  publicBenefits: IMembershipBenefit[] = []; //All membership benefits
  membershipCategories: IMembershipBenefitCategory[] = []; //All categories

  selectedCategory: string | undefined = undefined; //selected category
  selectedMainCategory: string | undefined = undefined; //selected category
  errorMessage: string = '';
  public selectedBenefit: IMembershipBenefit | undefined = undefined;


  public dropdownBtnText = "Viss alle";

  constructor(
    private membershipService: MembershipService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer
  ) {
    this.spinner.show();    
    this.GetMembershipBenefits();
    this.spinner.hide();

  }

  ngOnInit(): void {
    this.spinner.show();
    /* -------------------------------------------------------------------------- */
    /*                      Get data for Medlemsfordeler meny                     */
    /* -------------------------------------------------------------------------- */
    /*
        this.crmService.GetPicklistValues("ctg_webpage", "ctg_medlemsfordelerkategori").subscribe({
          next: (data: IPicklistCategories[]) => {
            this.membershipCategories = data;
    
          }
        });
    
    */
  }
  //Onclick event to show fullscreen
  displayFullscreen(webpageid: string): void {
    this.spinner.show();
    this.selectedBenefit = this.benefits.filter(
      (b) => b.ctg_webpageid == webpageid
    )[0];
    this.spinner.hide();
  }  

  public transformImg(base64Image: string) {
    return (this.sanitizer.bypassSecurityTrustResourceUrl(base64Image) as any).changingThisBreaksApplicationSecurity;
  }

  private GetMembershipBenefits() {

    this.membershipService.GetMembershipBenefitsInitial().subscribe({
      next: (data) => {

        if (!!data.menu) {
          this.membershipCategories.length = 0;
          let firstRecord = { ctg_name: "Viss alle", ctg_webkategorimedlemsfoldid: "", label: "", value: 0 };
          let mainMenuItems = data.menu.filter((e: IMembershipBenefitCategory) => e.ctg_maincategory == false || e.ctg_maincategory == null);
          mainMenuItems.push(firstRecord);
          this.membershipCategories.push(...mainMenuItems);
        }

        if (data.benefits.length == 0) {
          this.errorMessage = 'Fant ingen medlemsfordeler!';

          this.spinner.hide();

        } else {
          this.spinner.show();
          this.benefits = data.benefits;
          this.publicBenefits = data.benefits;

          this.spinner.hide();
        }
      },
      error: (err) => {
        this.spinner.hide();
        console.error('errorMessage: ' + err);
        this.errorMessage = 'En feil skjedde!';
      },
    });
  }


  public onClickMembershipCategories(_cValue: string) {
    const _btnName = this.membershipCategories.find(c => c.ctg_webkategorimedlemsfoldid == _cValue);
    this.dropdownBtnText = _btnName?.ctg_name!;
    if (_cValue !== "") {
      const _currentItem = this.benefits.filter(b => b.ctg_KategoriMedlemsfoldId.ctg_webkategorimedlemsfoldid == _cValue);
      this.publicBenefits = _currentItem;
    } else {
      this.publicBenefits = this.benefits;
    }
  }



  public randomBgColor(): string {

    let colors = ["#E2C6AD", "#F5D0CD", "#CDEEF5", "#A0CFD9", "#BACCD0", "#C0E4D9", "#A8CAC0", "#83A69B", "#D1CAD3", "#CCB8D1", "#BC92C9"];
    const result = colors[~~(Math.random() * colors.length)];

    return result;
  }
}



function data(
  value: IMembershipBenefitCategory,
  index: number,
  array: IMembershipBenefitCategory[]
): unknown {
  throw new Error('Function not implemented.');
}
