<div class="container-fluid ">
  <main class="container container-main">
    <div class="row">
      <div class="col-12">
        <div class="row ">
          <div class="col-12 bg-primary-header ps-4 mt-5  text-start">
            <p class="fs-4 fw-normal pt-3">Medlemsfordeler</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-4">
        <div ngbDropdown class="dropdown mt-5 mb-5">
          <button ngbDropdownToggle class="btn btn-secondary dropdownBTN" type="button" id="dropdownMenuButton1"
            aria-expanded="false">
            {{ dropdownBtnText }}
          </button>

          <ul ngbDropdownMenu aria-labelledby="dropdownMenuButton1"class="btn btn-secondary dropdownInBtn" >
            <ng-container *ngFor="let category of membershipCategories">
              <li ><a ngbDropdownItem (click)="onClickMembershipCategories(category.ctg_webkategorimedlemsfoldid)"> {{category.ctg_name}} </a></li>
            </ng-container>

          </ul>
        </div>
      </div>
    </div>

  
    <div class="row mb-5 justify-content-center" style="overflow: hidden" >
      <ng-container *ngFor="let benefitsItem of publicBenefits">
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 membershipbenefit-card " style="cursor: pointer" (click)="displayFullscreen(benefitsItem.ctg_webpageid)" >
          <div class=" p-3 ms-2 me-2 border rounded " [style.background-color]="randomBgColor()">
            <!-- <h5>{{benefit.ctg_heading}}</h5> -->
            <!-- <br> -->
            <div class="img-container">
              <img [src]="transformImg(benefitsItem.ctg_logofile)" />
            </div>
            <br />
            <span class="title  text-center">{{
              benefitsItem.ctg_KategoriMedlemsfoldId.ctg_webkategorimedlemsfoldid
              ? (benefitsItem.ctg_KategoriMedlemsfoldId.ctg_webkategorimedlemsfoldid | getpicklistlabel
              : membershipCategories
              : benefitsItem.ctg_KategoriMedlemsfoldId.ctg_webkategorimedlemsfoldid)
              : ""
              }}</span>
            <br />
            <div [innerHTML]="benefitsItem.ctg_maintext" class="body-text text-center fs-4"></div>
          </div>
        </div>
      </ng-container>
    </div>


 
  </main>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Laster... </p>
</ngx-spinner>
<div class="fullScreen " *ngIf="selectedBenefit != undefined">
  <div class="container-fullscreen">
    <a (click)="selectedBenefit = undefined" class="fullscreen-close" style="
        font-weight: bold;
        text-decoration: none;
        cursor: pointer;
        font-size: 15px;
      ">Lukk <span style="font-size: 18px; vertical-align: middle">✕</span></a>

    <div class="container-fullscreen-white">
      <h2 [innerText]="selectedBenefit.ctg_heading"></h2>
      <div class="fullscreen-image-container mb-4 mb-md-0">
        <img [src]="transformImg(selectedBenefit.ctg_bilde)"
          [class]=" 'image ' + (selectedBenefit.ctg_bilde == null ? 'd-none' : '') " />
      </div>
      <div [innerHTML]="selectedBenefit.ctg_innhold"></div>
    </div>
    <div class="row">
      <div class="col-12"></div>
    </div>
  </div>
</div>