
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IPicklistCategories, IPicklistResponse } from '@app/xModels/CrmModel';
import { IFileGetApiModel } from '@app/xModels/FileGetApiModel';
import { RegistrationUser } from '@app/xModels/RegistrationUser';
import { UserContacts } from '@app/xModels/UserContacts';
import { RequestNewMembership, UserMembership, RequestEndMembership } from '@app/xModels/UserMembership';
import { IMembershipBenefit, IMembershipBenefitCategory } from '@app/xModels/UserMembershipBenefits';
import { environment } from '@environments/environment';
import { map, Observable } from 'rxjs';
import { CrmService } from './crm.service';
import { BaseModel } from '@app/xModels/BaseModel';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  constructor(private crmService: CrmService, private domSanitizer: DomSanitizer) { }


  public GetMembershipBenefits() {
    let endpoint = "ctg_webpages?$select=ctg_maintext,ctg_bilde_url,ctg_bilde,ctg_name,ctg_innhold,ctg_heading,ctg_logofile,ctg_logofile_url&$filter=ctg_publishedweb eq true and ctg_KategoriMedlemsfoldId/ctg_webkategori eq 1 &$expand=ctg_KategoriMedlemsfoldId($select=ctg_webkategorimedlemsfoldid)&$orderby=createdon desc";
    return this.crmService.get<any>(endpoint).pipe(
      map(
        data => {
          delete data['@odata.context'];
          data.value.forEach((m: any) => {
            m.ctg_webkategorimedlemsfoldid = m.ctg_KategoriMedlemsfoldId.ctg_webkategorimedlemsfoldid;
            delete m['@odata.etag'];
          });
          return data.value;
        })
    );
  }

  public GetMembershipBenefitsInitial() {
    let endpoint = "MembershipBenefit/Initial";
    return this.crmService.get<BaseModel<any>>(endpoint).pipe(
      map(
        data => {
          return data.responseData;
        })
    );
  }

  public GetInboxInitialData() {
    let endpoint = "Inbox/News";
    return this.crmService.get<BaseModel<any>>(endpoint).pipe(
      map(
        data => {
          return data.responseData;
        }
      ))
  }

  public GetInboxCount() {
    let endpoint = "Inbox/Count";
    return this.crmService.get<BaseModel<any>>(endpoint).pipe(
      map(
        data => {
          return data.responseData;
        }
      ))
  }

  public getInboxReadItems() {
    let endpoint = "Inbox/ReadItems";

    return this.crmService.get<BaseModel<string[]>>(endpoint).pipe(
      map(
        data => {
          return data.responseData;
        })
    );
  }

  public setNewsAsRead(newsId: string) {
    let endpoint = "Inbox/SetRead/" + newsId;

    /*
     let endpoint = "contacts( " + contactId + ")/ctg_webpage_contactid/$ref";

    var association = {
      "@odata.id": environment.crmUrl + '/' + "ctg_webpages(" + newsId + ")"
    };
*/
    return this.crmService.post<BaseModel<string[]>>(endpoint, null).pipe(
      map(
        data => {
          return data.responseData;
        })
    );
  }

  public getNewsImageBanner(newsId: string) {
    let endpoint = "Inbox/downloadbanner/" + newsId;
    return this.crmService.post<BaseModel<any>>(endpoint, null).pipe(
      map(
        data => {
          return data.responseData;
        })
    );
  }

  public TerminateMembership(currentMembership: UserMembership, contactId: string, reasons?: number, reasonText?: string) {
    var record: any = {};
    record.ToDate = currentMembership.memberTo; // Date Time
    record.InvoiceEndDate = currentMembership.memberTo; // Date Time
    record.ReasonCancellation = reasons;
    record.contactId = contactId;
    if (!this.isEmptyOrSpaces(reasonText!)) {
      record.reasonCancellationDesc  = reasonText;
    }

    var endPoint = "Membership/terminate/" + currentMembership.id;
    return this.crmService.patch<any>(endPoint, record);
  }


  public changeMembership(currentMembership: UserMembership, newMembership: RequestNewMembership) {
    var record = {
      CurrentMembership: {
        ToDate: currentMembership.memberTo,
        InvoiceEndDate: currentMembership.memberTo,
      },
      NewMembership: newMembership,
    };

    var endPoint = "Membership/change/" + currentMembership.id;
    return this.crmService.post<any>(endPoint, record);
  }

  public GetAllMembershipByContactId(ContactId: string) {
    var endPoint = "Membership/user/" + ContactId;
    return this.crmService.get<BaseModel<UserMembership[]>>(endPoint);
  }



  public createNewMembership(membershipId: string, startDate: Date, contactId: string) {

    var record: any = {};
    record.fromDate = startDate;
    record.membershipId = membershipId;
    record.contactId = contactId;
    record.invoiceStartDate = startDate;

    //var endPoint = "acan_medlemskaps";
    var endPoint = "membership/new";
    let sendData = JSON.stringify(record);
    return this.crmService.post<any>(endPoint, sendData, false);
  }


  public GetFullSizeBlobImage
    (fieldModel: IFileGetApiModel) {
    let endpoint = "MembershipBenefit/getFullSizeBlob";
    // entity + "(" + webpageid + ")/" + fieldName + "/$value?size=full"  
    return this.crmService.post(endpoint, fieldModel);
  }


  public GetFullSizeImage(fieldModel: IFileGetApiModel): Observable<BaseModel<string>> {
    let endpoint = "MembershipBenefit/getFullSizeImage";
    // entity + "(" + webpageid + ")/" + fieldName + "/$value?size=full"  
    return this.crmService.post<BaseModel<string>>(endpoint, fieldModel);
  }

  private isEmptyOrSpaces(str: string | undefined) {
    if(str === undefined){
      return true
    }else      
    return str === null || str!.match(/^ *$/) !== null;
  }

};

