import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { AddContactIdAction, RemoveContactIdAction } from "./contact-id.actions";



export interface ContactIdStateModel {
    contactIdData: string;
}



@State<string>({
    name: 'contactId',
    defaults: "",
})

@Injectable()
export class ContactIdState {

    @Action(AddContactIdAction)    
    add(ctx: StateContext<ContactIdStateModel>,  action : AddContactIdAction,) {
        
        const {payload} = action; 

        ctx.setState({contactIdData:  payload,});
        console.log(ctx.getState());
    }

    
    @Action(RemoveContactIdAction)
    remove({patchState}: StateContext<ContactIdStateModel>): void {
        patchState({contactIdData: "" });
    
   }

}