import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TagInputModule } from 'ngx-chips';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, SafePipe } from './app.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@environments/environment';
import { NgxsModule } from '@ngxs/store';

//**Custom controllers */

import { RegistrationComponent } from './registration/registration.component';
import { LoginComponent } from './auth/login/login.component';
import { MyNavComponent } from './nav/my-nav/my-nav.component';
import { UserContactsComponent } from './user-contacts/user-contacts.component';
import { UserMembershipComponent } from './user-membership/user-membership.component';
import { InvoiceMainComponent } from './invoice/invoice-main/invoice-main.component';

import { BankIdComponent } from './auth/bank-id/bank-id.component';
import { BankIdRedirectComponent } from './auth/bank-id-redirect/bank-id-redirect.component';
import { HomeComponent } from './home/home.component';
import { UserInboxComponent } from './user-inbox/user-inbox.component';
import { UserMyPageComponent } from './user-my-page/user-my-page.component';
import { UserInvoicesComponent } from './user-invoices/user-invoices.component';
import { WelcomeMyPageComponent } from './welcome-to-my-page/welcome-to-my-page.component';
import { FooterComponent } from './footer/footer.component';
import { FromcrmComponent } from './auth/fromcrm/fromcrm.component';
import { LogoutComponent } from './logout/logout.component';
import { UserMembershipbenefitsComponent } from './user-membershipbenefits/user-membershipbenefits.component';
import { RegistrationUpdateComponent } from './registration-update/registration-update.component';
import { VippsCaptureComponent } from './auth/vipps-capture/vipps-capture.component';

//**Custom Services, Interceptors and other      */
import { HttpConfigInterceptor } from './xInterceptors/httpconfig.interceptor';
import { DecryptPipe, EncryptPipe } from './xServices/enctypt-decrypt.pipe';
import { TokenService } from './xServices/token.service';
import { GetFullSizeImageFromCRM, GetPicklistLabel, ReturnBase64FromBlob } from './xServices/crm.pipe';
import { PhoneFormatPipe } from './xPipes/phone-format.pipe';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { UserInfoState } from './store/user-info/user-info.state';
import { StoreManageService } from './xServices/StoreManageService.service';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { ContactIdState } from './store/contact-id/contact-id.state';
import { HelperService } from './xServices/help.service';
import { Base64Pipe } from './xPipes/imageBase64.pipe';
import { UserWorkplaceComponent } from './user-workplace/user-workplace.component';
import { UserEducationComponent } from './user-education/user-education.component';
import { UserPasswordComponent } from './user-password/user-password.component';
import { RegistrationResultComponent } from './registration-result/registration-result.component';
import { UserCourseComponent } from './user-course/user-course.component';
import { UserCourseInfoComponent } from './user-courseinfo/user-courseinfo.component';
import { UserCourseResultComponent } from './user-courseresult/user-courseresult.component';
import { UserMemberGroupComponent } from './user-membergroup/user-membergroup.component';
import { DatePipe } from '@angular/common';
import { PaginationModule } from "ngx-bootstrap/pagination";
import { DialogSendEmailComponent } from './dialog/dialogsendemail/dialog-sendemail.component';



@NgModule({
  declarations: [
    AppComponent,
    RegistrationComponent,
    LoginComponent,
    MyNavComponent,
    UserContactsComponent,
    UserMyPageComponent,
    UserMembershipComponent,
    UserInvoicesComponent,
    InvoiceMainComponent,
    SafePipe,
    PhoneFormatPipe,
    Base64Pipe,
    BankIdComponent,
    BankIdRedirectComponent,
    HomeComponent,
    UserMembershipbenefitsComponent,
    GetFullSizeImageFromCRM,
    GetPicklistLabel,
    ReturnBase64FromBlob,
    FromcrmComponent,
    LogoutComponent,
    RegistrationUpdateComponent,
    UserInboxComponent,
    WelcomeMyPageComponent,
    FooterComponent,
    UserWorkplaceComponent,
    UserEducationComponent,
    UserPasswordComponent,
    RegistrationResultComponent,
    VippsCaptureComponent,
    UserCourseComponent,
    UserCourseInfoComponent,
    UserCourseResultComponent,
    UserMemberGroupComponent,
    DialogSendEmailComponent,
  ],
  imports: [
    TagInputModule, 
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PaginationModule,
    NgbModule,
    NgxSpinnerModule,
    NgxsModule.forRoot([UserInfoState,ContactIdState], {developmentMode: !environment.production}),
    NgxsStoragePluginModule.forRoot({
      key: ['userInfo', 'contactId']
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsSelectSnapshotModule.forRoot()
  ], 
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    TokenService, 
    EncryptPipe, 
    DatePipe,
    DecryptPipe,
    StoreManageService,
    HelperService,
    TokenService,
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
