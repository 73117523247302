
<div class="container h-75">
  <div class="row">
    <div class="col-12 bg-primary-header  mt-5  text-start ps-4">
      <p class="fs-4 pt-3 fw-normal">Kursinformasjon</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-5 text-start">
      <h2 class="message-text-header">{{ data?.courseName }}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 col-lg-8 col-sm-12">
      <!--img src="../../assets/img/example/Bli-medlem-780x439.jpg" class="course-main-image" /-->
      <div class="mt-3 mb-3">
        <!--h4 class="message-text-header">Oversikt</!--h4-->
        <div [innerHTML]="data?.description"></div>
      </div>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-12">
      <div class="course-sidebar register-form" *ngIf="mode == 'register'">
        <div class="course-data-block" >
          <form [formGroup]="registerCourseForm">
            <div class="row">
              <div class="col-12">
                <h4>Registreringsskjema</h4>
                <h6>Fyll ut skjemaet nedenfor</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="nInput3" class="form-label small">Send faktura via</label>
                <select class="form-select" formControlName="sendInvoiceVia" id="nInput3">
                  <option *ngFor="let opt of invoiceOption" [value]="opt.value">{{opt.label}}</option>
                </select>
              </div>
              <div class="col-12" *ngIf="data?.isPayable">
                <label for="txtInvoiceReference" class="form-label small">Fakturareferanse</label>
                <input type="text" 
                  class="form-control" 
                  id="txtInvoiceReference" 
                  formControlName="txtInvoiceReference" 
                  maxlength="100"
                  />
              </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
              <div class="col-6">
                <button class="btn btn-primary"  (click)="submitRegister()">Registrer</button>
              </div>
              <div class="col-6">
                <button class="btn btn-danger"  (click)="cancelRegister()">tilbake</button>
              </div>
              
            </div>
          </form>
        </div>
      </div>
      <div class="course-sidebar">
        <div class="course-data-block">
          <div class="course-block" *ngIf="data?.isPayable">
            <div class="course-info-title">Pris</div>
            <div>{{ data?.price | number:'2.2-2' }}</div>
          </div>
          <div class="course-block">
            <div class="course-info-title">Tidsperiode</div>
            <div>{{ displayDateRange(data) }}</div>
          </div>
          <div class="course-block" *ngIf="data?.duration">
            <div class="course-info-title">Varighet</div>
            <div>{{ data?.duration }}</div>
          </div>
          <div class="course-block" *ngIf="data?.maxParticipants">
            <div class="course-info-title">Maks deltakere</div>
            <div>{{ data?.maxParticipants }}</div>
          </div>
          <div class="course-block" *ngIf="data?.locationName">
            <div class="course-info-title">Sted</div>
            <div>{{ data?.locationName }}</div>
          </div>
        </div>
        <div *ngIf="mode == 'read'">
          <button class="btn btn-primary"  (click)="showRegister()">Registrer</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Laster... </p>
</ngx-spinner>
