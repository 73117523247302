import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import { CourseInfo, CourseResponse } from '@app/xModels/CourseModel';
import { IPicklistCategories } from '@app/xModels/CrmModel';
import { IFileGetApiModel, FileGetApiModel } from '@app/xModels/FileGetApiModel';
import { IKategoriMedlemsFoldId, IMembershipBenefit, IMembershipBenefitCategory } from '@app/xModels/UserMembershipBenefits';
import { IUserNews } from '@app/xModels/UserNews';
import { CourseService } from '@app/xServices/course.service';
import { CrmService } from '@app/xServices/crm.service';
import { DecryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseModel, OptionSetItem, OptionSetString } from '@app/xModels/BaseModel';
import swal from 'sweetalert2';

@Component({
  selector: 'app-user-course',
  templateUrl: './user-course.component.html',
  styleUrls: ['./user-course.component.scss']
})


export class UserCourseComponent implements OnInit {
 
  availableDatas: Array<CourseInfo> = [];
  registeredDatas: Array<CourseInfo> = [];
  filterDatas: Array<CourseInfo> = [];
  categoryOption: Array<OptionSetString> = [ { label: 'alle kategorier', value: '' } ];
  semeterOption: Array<OptionSetString> = [ { label: 'hele semesteret', value: '' } ];
  categoryColor: Array<string> = [ 'color-block-lblue', 'color-block-green', 'color-block-dblue' ];
  categoryFilter: string = '';
  semeterFilter: string = '';

  constructor(private service: CourseService, 
    private sanitizer: DomSanitizer, 
    private spinner: NgxSpinnerService,
    private router : Router,
    private datepipe: DatePipe) {
      this.GetInitialData();
  }

  ngOnInit(): void { 
    
  }

  private GetInitialData() {
    this.spinner.show();
    this.service.getCourses().subscribe({
      next: (data: BaseModel<CourseResponse>) => {
        if (!!data && data.responseData) {
          this.availableDatas = data.responseData.coursesAvailable.sort((a, b) => new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime());
          this.registeredDatas = data.responseData.coursesRegistered.sort((a, b) => new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime());
          this.categoryOption = [ ...this.categoryOption, ...data.responseData.categoryOption ];
          this.semeterOption = [ ...this.semeterOption, ...data.responseData.semesterOption ];
        } 
      },
      complete: () => {
        this.spinner.hide();
      }
    });

  }

  renderCourse() {
    if (this.availableDatas) {
      return this.availableDatas.filter(a => 
        (this.semeterFilter == '' ? a.semesterId == a.semesterId: a.semesterId ==  this.semeterFilter) &&
        (this.categoryFilter == '' ? a.categoryId == a.categoryId: a.categoryId ==  this.categoryFilter)
      );
    }
    else {
      return [];
    }
  }
  filterSemesterChange(event: any) {
    this.semeterFilter = event.value;
  }
  filterCategoryChange(event: any) {
    this.categoryFilter = event.value;
  }
  renderColor(i: number): string {
    if (this.categoryColor.length > i) {
      return this.categoryColor[i];
    }
    else {
      return this.categoryColor[0];
    }
  }

  displayDateRange(counse: CourseInfo): string {
    //let formattedDt = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ssZZZZZ', 'en_US')
    let fdate = counse.dateFrom ? new Date(counse.dateFrom).toLocaleDateString() : '';
    let edate = counse.dateTo ? new Date(counse.dateTo).toLocaleDateString() : '';
    if (fdate == edate) {
      return this.datepipe.transform(counse.dateFrom, 'dd.MM.yyyy') ?? '';
    }
    else {
      return `${this.datepipe.transform(counse.dateFrom, 'dd.MM.yyyy')}-${this.datepipe.transform(counse.dateTo, 'dd.MM.yyyy')}`;
    }
  }
  
  cancelCourseRegister(couseId: string) {
    swal.fire({
      title: 'bekrefte for kansellering',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'bekrefte',
      cancelButtonText: "kansellere",
    }).then((isConfirm:any) => {
      if (isConfirm) {
        this.confirmToCancelCourse(couseId);
      }
    });
  }

  confirmToCancelCourse(couseId: string) {
    this.service.UnRegisterCourse(couseId).subscribe(res => {
      if(res && res.responseData != '') {
        swal.fire({
          title: 'Påmeldingen din er annulert',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then((result:any) => {
            this.GetInitialData();
        });
      }
      else {
        swal.fire({
          title: 'Error',
          text: res.description,
          icon: 'warning',
          confirmButtonText: 'OK',
        });
      }
    });
  }
}
