import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EncryptPipe } from '@app/xServices/enctypt-decrypt.pipe';

@Component({
  selector: 'app-fromcrm',
  templateUrl: './fromcrm.component.html',
  styleUrls: ['./fromcrm.component.scss']
})
export class FromcrmComponent implements OnInit {
  private sub: any;

  public parameters: string = "";
  constructor(private route: ActivatedRoute, private router: Router, private encryptPipe: EncryptPipe) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.parameters = params['parameter']; // (+) converts string 'id' to a number
      if (this.parameters !== undefined && this.parameters !== null) {
        console.info("Login params :" + this.parameters);
        this.SetPersonalIdToCache(this.parameters)
      }
      else
        localStorage.removeItem("adamCGUID");
    });
    this.router.navigateByUrl('/login');
  }


  private SetPersonalIdToCache(userID: string) {
    try {
      let stringData = JSON.stringify(userID);
      let encryptedData = this.encryptPipe.transform(stringData)
      localStorage.setItem("adamCGUID", encryptedData); //adamCGUID - CRM Guid
    } catch (error) {
      console.error("Can not set personID to cache")
    }
  }

}
