import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenService } from '@app/xServices/token.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private tokenService :TokenService,   private router: Router,
    private r: ActivatedRoute) {
    
    this.tokenService.ClearlocalStorage();
    localStorage.removeItem("adamCGUID");
    this.router.navigate(["/login"], { relativeTo: this.r });
  }

  ngOnInit(): void {
  }

}
