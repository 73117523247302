import { Injectable } from "@angular/core";
import { Client } from "@vippsmobilepay/sdk";
import { RootService } from "./root.service";
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CrmService } from "./crm.service";
import { map, Observable } from "rxjs";
import { BaseModel } from "@app/xModels/BaseModel";
import { VippsChargeCaptureResponse } from "@app/xModels/VippsMobilePayment";

@Injectable({
    providedIn: 'root'
})
export class VippService {

    constructor(private crmService: CrmService) { }

    public registerToVippAgreement(contactid: string, membershipId: string, requireVerifyEmail: boolean): Observable<string> {
        let sendData = JSON.stringify({
            contactid: contactid,
            membershipId: membershipId,
            requireVerifyEmail: requireVerifyEmail
        });
        return this.crmService.post<BaseModel<any>>("VippsPayment/RegisterRecurringPayment", sendData).pipe(
            map(data => {
                if(data.responseData && data.responseData.redirectUrl != '') {
                    return data.responseData.redirectUrl;
                }
                return '';
            })
          );
    }

    public captureChargeRedirect(merchantInvoiceId: string,  contactId: string,  isRegisterWithMember: boolean): Observable<BaseModel<VippsChargeCaptureResponse>> {
        var json = { 
            merchantInvoiceId: merchantInvoiceId,
            contactId: contactId,
            isRegisterWithMember: isRegisterWithMember
        };
        return this.crmService.post<BaseModel<VippsChargeCaptureResponse>>("VippsPayment/CaptureVippCharge", json);
    }

    public getRedirectLoginUrl(): Observable<BaseModel<string>> {
        return this.crmService.get<BaseModel<string>>("VippsAuth/GetVippsLoginLink");
    }

    public authenWithCode(code: string): Observable<BaseModel<string>> {
        return this.crmService.get<BaseModel<string>>("VippsAuth/verify/" + code);
    }
}