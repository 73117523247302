
<!-- Navigation -->
<div class="container-bg-color">
    <!-- Main Content Area -->
    <main class="container pt-5  d-flex justify-content-center justify-sm-content-center ">
        <div class="row  start-page-card">
            <div class="col-lg-6 col-md-6 col-sm-12 ">
                <div class="card ">
                    <div class="card-body ps-5 pe-5 pt-5 text-center ">
                        <h3 class="card-title  mb-5">Logg inn på Min side</h3>
                        <p class="card-text lh-base "> Logg inn og få tilgang til dine medlems-fordeler,
                            betaling og administrering av ditt medlemskap.</p>
                        <button class="btn btn-primary mt-4 p-3 w-100 btn-login-navigator" type="submit" [routerLink]="['/login']">
                            Logg inn
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="card right-card text-white  ">
                    <div class="card-body right-card pt-5 pb-5  text-center  ms-md-5 me-md-5 ">
                        <h4 class="card-title fs-5 mb-5 text-white">Førstegangsbruker?</h4>
                        <p class="card-text "> Første gang du logger på Min Side, må du trykke på linken i invitasjonen vi har sendt til din registrerte e-post. 
                            </p>
                            <label class="card-text " >Om du ikke finner e-posten eller om invitasjonen har utløpt – vennligst kontakt oss direkte på post@cartagena.no eller ring </label><p> 22 72 00 20.</p>
                    
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>