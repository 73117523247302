import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { IPicklistCategories } from '@app/xModels/CrmModel';
import { Membership } from '@app/xModels/Membership';
import { RegistrationUser, RegistrationWorkplace } from '@app/xModels/RegistrationUser';
import { UserStatusModel } from '@app/xModels/UserStatusModel';
import { CrmService } from '@app/xServices/crm.service';
import { DecryptPipe, EncryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { TokenService } from '@app/xServices/token.service';
import { UserService } from '@app/xServices/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-registration-result',
  templateUrl: './registration-result.component.html',
  styleUrls: ['./registration-result.component.scss']
})
export class RegistrationResultComponent implements OnInit {
  private sub: any;
  private parameter: string = "";

  statusType: string = '';
  displayMessageStatus: string = '';

  constructor(private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private r: ActivatedRoute,
  ) {
    this.sub = this.route.params.subscribe(params => {
      this.parameter = params['parameter'];
    });
  }

  ngOnInit(): void {
    if (this.parameter == 'verifyemail') {
      this.statusType = 'info';
      this.displayMessageStatus = 'Søknaden din er sendt inn. Vennligst sjekk e-posten din for å bekrefte og angi passordet ditt.';
    }
    else if (this.parameter == 'sendforgetmail') {
      this.statusType = 'info';
      this.displayMessageStatus = 'Vi sender lenken for tilbakestilling av passord til e-posten din. Vennligst bekreft og fortsett.';
    }
  }

}
