import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IPicklistCategories } from '@app/xModels/CrmModel';
import { IFileGetApiModel, FileGetApiModel } from '@app/xModels/FileGetApiModel';
import { IKategoriMedlemsFoldId, IMembershipBenefit, IMembershipBenefitCategory } from '@app/xModels/UserMembershipBenefits';
import { IUserNews } from '@app/xModels/UserNews';
import { CrmService } from '@app/xServices/crm.service';
import { DecryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { MembershipService } from '@app/xServices/membership.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-inbox',
  templateUrl: './user-inbox.component.html',
  styleUrls: ['./user-inbox.component.scss']
})


export class UserInboxComponent implements OnInit {
 
 //<img src="assets/img/icons/envelope-unread.png" alt="">
  public inboxItems:IUserNews[]| undefined = undefined;//All membership benefits
  public inboxCategories: IMembershipBenefitCategory[] = []; //All categories
  selectedCategory: string | undefined = undefined; //selected category
  errorMessage: string = "";
  public selectedBenefit: IUserNews | undefined = undefined;
  public openToRead:boolean=false;
  public isSmallDevice:boolean = window.innerWidth <= 900
  public readItems?: IUserNews;
  userId: string = "";

  constructor(private membershipService: MembershipService, 
    private sanitizer: DomSanitizer, 
    private spinner: NgxSpinnerService,
    private router : Router) {
      this.spinner.show();
      this.GetInitialInboxData();
  }

  ngOnInit(): void { 
    
  }

  private GetInitialInboxData() {
    this.membershipService.GetInboxInitialData().subscribe({
      next: (data: any) => {
        if (!!data) {
          //SET DATA
          this.inboxItems = data;  
          if (!this.isSmallDevice) {     
            this.readItems = this.inboxItems!.find(x=>x.readedNews==true);
            if (this.readItems){
              this.openToRead = true;
              this.readItems.newsSelected = true;
              this.downloadNewsBanner(this.readItems);
            }

            if (!this.readItems && !!this.inboxItems) // Only desktop version: auto open news
            {
              this.readItems =this.inboxItems[0]
              this.openForRead(this.readItems);
            }
          }
        } else {
          this.errorMessage = "Fant ingen elementer!";
          
        }
      },
      complete: () => {
        this.spinner.hide();
      }
    });

  }

  public downloadNewsBanner(_item:IUserNews) {
    if (!_item.banner) {
      this.membershipService.getNewsImageBanner(_item.newsid).subscribe({
        next: data => {
          _item.banner = data;
        }
      });
    }
  }

  public openForRead(_item:IUserNews) {
    this.readItems = _item;
    this.openToRead = true;
    this.inboxItems?.filter(a => a.newsid != _item.newsid).forEach(a => a.newsSelected = false);
    _item.newsSelected = true;
    
   // Set as read
    if (!_item.readedNews) {
      this.membershipService.setNewsAsRead(_item.newsid).subscribe({
        next: () => {
          _item.readedNews=true;
          this.inboxItems!.forEach(element => {
            if(element.newsid ==_item.newsid)
            element.readedNews==true;
          });       
        }
      });
    }

    this.downloadNewsBanner(_item);
  }

  public closeNews() {
    this.openToRead = false;
  }

  public closeMobileNews() {
    if (this.isSmallDevice){
      this.closeNews();
    }
  }
}
