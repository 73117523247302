<div class="container-bg-color">
  <main class="container container-main ">

    <div class="row justify-content-center">
      <div class="col-md-8 mt-5 mb-5 pb-5 text-center">
        <h2 class="mb-5" style="font-weight: bold;">Velkommen til Cartagena</h2><br />
        <p class="fs-5">Du er kun noen tastetrykk unna tilgang på noen av landets beste rådgivere og jurister. Ditt
          medlemskap er anonymt, sikkert, rimelig og har ingen bindingstid. </p>
        <br /> <br />
        <div class="fs-5">
          1. Logg inn med BankID.<br>
          2. Fyll ut kontaktinfo.<br>
          3. Velkommen som medlem.<br>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-8 text-center mb-5">
        <button class="btn btn-primary pt-2 pe-4 pb-2 ps-4 text-nowrap" type="submit" [routerLink]="['/login']">
          Bli medlem
        </button>
      </div>
    </div>
  </main>
</div>