
<div class="container-md text-center">
  <div class="mt-5 mb-5 pb-5  col-sm-5 mx-auto w-75 mb-5">
  <ngb-alert [type]="statusType" *ngIf="noSuccessMessage" [dismissible]="false" class="pt-5">
    <span *ngIf="statusType=='danger'"><strong >Vi beklager, men noe gikk galt!</strong>
    </span>
    <div class="mt-5 pb-5 text-center">
      <button type="button" class="btn btn-primary" (click)="StatusCheck()" >Prøv på nytt </button>
    </div>
  </ngb-alert>
  <ngb-alert [type]="statusType" *ngIf="successMessage" [dismissible]="false">
    <span *ngIf="statusType=='success'" ><strong
        >Vellykket.</strong></span>
  </ngb-alert>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Laster... </p>
</ngx-spinner>
</div>