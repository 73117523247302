import { Pipe, PipeTransform } from '@angular/core';
import { IPicklistCategories } from '@app/xModels/CrmModel';
import { fromEvent, map, Observable, of } from 'rxjs';
import { MembershipService } from './membership.service';
import { DomSanitizer } from '@angular/platform-browser';
import { IFileGetApiModel } from '@app/xModels/FileGetApiModel';

@Pipe({
  name: 'getfullsizeimage'
})
export class GetFullSizeImageFromCRM implements PipeTransform {
  
  constructor(private membershipService: MembershipService) {
  }

  transform(value: string, entity: string, id: string, fieldName: string): void {
// new parameter is ( fieldModel:IFileGetApiModel )
let fieldModel:IFileGetApiModel={webpageid:id,fieldType:fieldName};
    this.membershipService.GetFullSizeBlobImage(fieldModel).subscribe({
      next: (data:any) => {
        var reader = new FileReader();
        reader.onloadend = function () {
          let base64data = reader.result;
          return base64data;
        }
        reader.readAsDataURL(data);
      }
    });
  }
}


@Pipe({
  name: 'getpicklistlabel'
})
export class GetPicklistLabel implements PipeTransform {
  constructor() { }
  transform(value: string, dataset: IPicklistCategories[], picklistValue: string): string {
    let val = parseInt(picklistValue);
    let label = dataset.find(e => e.value == val)?.label;
    if (!label)
      label = "";
    return label;
  }
}

@Pipe({
  name: 'returnBase64FromBlob',
  pure: true,
})
//returns string : Use in html like this: <img [src]="(base64file | returnBase64FromBlob | async)" >
export class ReturnBase64FromBlob implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: Blob): any {
    if (!value)
      return of("");
    const reader = new FileReader();
    reader.readAsDataURL(value);
    reader.onloadend = function () {
      return reader.result;
    }
    return fromEvent<ProgressEvent>(reader, 'loadend')
      .pipe(
        map(e => {
          const targetImg = (e.target as FileReader | null);
          if (targetImg?.result) {
            return this.sanitizer.bypassSecurityTrustUrl(targetImg?.result.toString());
          } else {
            return "";
          }
        }
        )
      );

  }
  
}