
<div class="container-md text-center">
  <main class="container container-main " *ngIf="!isProcessing">
    <div class=" mb-3 row ">
      <div class="col-12 mt-5 mb-5 text-center">
        <h3>Vipps Capture status</h3>
      </div>
    </div>

    <ngb-alert type="info" [dismissible]="false" class="p-5 text-center">
      <label>We can't connect to Vipps Mobile payment and get your transaction right now. Will keep continue to check your transaction and let's you know.</label>
      <br>
      <button class="btn btn-lg btn-primary" id="btnSubmit" type="button"
                (click)="KeepContinue()">Continue</button>
    </ngb-alert>
  </main>

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Laster... </p>
  </ngx-spinner>
</div>