import { booleanAttribute, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreManageService } from '@app/xServices/StoreManageService.service';
import { TokenService } from '@app/xServices/token.service';
import { UserService } from '@app/xServices/user.service';
import { VippService } from '@app/xServices/vipp.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ctg-vipps-capture',
  templateUrl: './vipps-capture.component.html',
  styleUrls: ['./vipps-capture.component.scss']
})
export class VippsCaptureComponent implements OnInit {

  merchantInvoiceId: string | null;
  isRegisterWithMember: boolean;
  contactId: string | null;
  isProcessing: boolean = true;
  constructor(private spinner: NgxSpinnerService,
    private router: Router, 
    private r: ActivatedRoute,
    private userService: UserService,
    private vippService: VippService,
    private storeManageService: StoreManageService,
    private tokenService: TokenService
  ) {
    this.merchantInvoiceId = this.r.snapshot.queryParamMap.get('inv');
    this.isRegisterWithMember = (this.r.snapshot.queryParamMap.get('verifyemail')?.toLocaleLowerCase() == 'true' ?? false) ;
    this.contactId = this.r.snapshot.queryParamMap.get('contactid');
  }

  ngOnInit(): void {
    this.spinner.show();
    console.log(this.merchantInvoiceId);
    console.log(this.isRegisterWithMember);
    if (this.merchantInvoiceId) {
      this.vippService.captureChargeRedirect(this.merchantInvoiceId, this.contactId ?? '' , this.isRegisterWithMember).subscribe(res =>
        {
          this.spinner.hide();
          if (res && res.responseData) {
              if (res.responseData.isNeedToVerifyEmail) {
                this.router.navigate(["/registration/result/verifyemail"]);
                return;
              }
              else if (res.responseData.accessToken) {
                this.tokenService.ResetContactStorage();
                this.tokenService.SetToken(res.responseData.accessToken);
    
                const contactId = this.tokenService.GetIdentity().ContactId;
                this.storeManageService.AddContactId(contactId);
                this.router.navigate(["/mypage"]);
                return;
              }
          }

          this.KeepContinue();
        });
    }
    else {
      this.isProcessing = false;
    }
  }

  KeepContinue() {
    this.isProcessing = false;
    if (this.isRegisterWithMember) {
      this.router.navigate(["/registration/result/verifyemail"]);
    }
    else {
      this.router.navigate(["/mypage"]);
    }
  }

}
